import React from 'react'
import BlogCards from "../../components/BlogCard";
import BlogsCoverSection from "../../components/BlogsCoverSection";
import ContactForm from "../../components/ContactForm";
import FreeMeeting from "../../components/FreeMeeting";
import {Box} from "@mui/material";
import {Helmet} from "react-helmet-async";


const BlogsPage = () => {
    const metaTitle = "Uplumi Marketing | Blogs";
    const metaDescriptionContent = "Explore our engaging and informative content in the digital world. Fresh articles and innovative ideas await you. Explore our blog today!";
    const metaOGDescriptionContent = "Explore our engaging and informative content in the digital world. Fresh articles and innovative ideas await you. Explore our blog today!";

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>

                <meta
                    name="description"
                    content={metaDescriptionContent}
                />

                <meta property="og:title" content={metaTitle}/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://uplumi-marketing.com/opengraph/logo.jpg"/>
                <meta property="og:image:type" content="image/jpeg"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>
                <meta property="og:url" content="https://uplumi-marketing.com/blogs"/>
                <meta property="og:description" content={metaOGDescriptionContent}/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:site_name" content="Uplumi Marketing"/>
                <meta property="twitter:card" content="summary_large_image"/>

                <link rel="canonical" href="https://uplumi-marketing.com/blogs"/>
            </Helmet>

            <Box width="100%" sx={{background: 'white'}}>
                <BlogsCoverSection/>

                <BlogCards />

                <FreeMeeting />

                <ContactForm />
            </Box>
        </>
    )
}

export default BlogsPage
