import React from 'react'
import { Helmet } from "react-helmet-async";
import Contact from "../../components/Contact";

const AuditPage = () => {
    const metaTitle = "Uplumi Marketing | Free Marketing Consultation";
    const metaDescriptionContent = "Book a call with us now and find out how paid advertising, SEO and Email Marketing can grow and take your E-commerce or Info Product business to the next level.";
    const metaOGDescriptionContent = "Book a call with us now and find out how paid advertising, SEO and Email Marketing can grow and take your business to the next level.";

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>

                <meta
                    name="description"
                    content={metaDescriptionContent}
                />

                <meta property="og:title" content={metaTitle}/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://uplumi-marketing.com/opengraph/logo.jpg"/>
                <meta property="og:image:type" content="image/jpeg"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>
                <meta property="og:url" content="https://uplumi-marketing.com/contact"/>
                <meta property="og:description" content={metaOGDescriptionContent}/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:site_name" content="Uplumi Marketing"/>
                <meta property="twitter:card" content="summary_large_image"/>

                <link rel="canonical" href="https://uplumi-marketing.com/contact"/>
            </Helmet>

            <Contact />
        </>
    )
}

export default AuditPage
