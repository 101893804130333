import React, {useEffect, useState} from 'react'
import {Box, Select} from "@mui/material";
import {useTranslation} from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import ReactCountryFlag from "react-country-flag"
const styles = () => ({
    select: {
        '&:before': {
            borderColor: '#17131b',
        },
        '&:after': {
            borderColor: '#17131b',
        }
    },
    icon: {
        fill: 'white',
    },
});
const ChangeLanguage = () => {
    const classes = styles()
    const {i18n} = useTranslation(null, {keyPrefix: "navbar"})
    const [lang, setLang] = useState(i18n.language)

    useEffect(() => {
        changeLanguageHandler()
    }, [lang])

    const handleOnChange = async (event: any) =>
    {
        setLang(event?.target?.value)
    }

    const changeLanguageHandler = async () =>
    {
        await i18n.changeLanguage(lang)
    }

    return (
        <Box display="flex" justifyContent="end" sx={{backgroundColor: '#17131b', zIndex: 2, px: 2, gap: 2}}>
            {
                lang === 'ro' ?
                    <ReactCountryFlag
                        style={{
                            fontSize: '3em',
                            lineHeight: '1em',
                        }}
                        countryCode="RO"
                    />
                    :
                    <ReactCountryFlag
                        style={{
                            fontSize: '3em',
                            lineHeight: '1em',
                        }}
                        countryCode="US"
                    />

            }
            <Select
                value={lang}
                onChange={(e) => handleOnChange(e)}
                sx={{
                    color: 'white', boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '.MuiSvgIcon-root':{fill: 'white'}
            }}
                size="small"
                inputProps={{
                    classes: {
                        icon: classes.icon,
                    },
                }}
            >
                <MenuItem value="ro">Romanian</MenuItem>
                <MenuItem value="en">English</MenuItem>
            </Select>
        </Box>
    )

}

export default ChangeLanguage
