import React from 'react'
import {Box, Typography, useMediaQuery} from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {appPrimaryColor} from "../../../constants";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";

const AuditBenefits = () => {
    const {t} = useTranslation(null, {keyPrefix: "contact_page.audit_benefits"})
    const matches = useMediaQuery('(min-width:850px)')

    return (
       <Box display={matches ? 'flex' : 'grid'} padding={matches ? 8 : 2}>
           <Box display="grid" width={matches ? '60%' : '100%'} gap={4} alignContent="start">
               <Box display="grid" gap={1}alignContent="start">
                   <Typography variant="h2" fontSize="20px" color={appPrimaryColor} fontWeight={500} sx={{ pl: matches ? 0 : 2, pr: matches ? 0 : 2, pt: matches ? 0 : 2}}>
                       {t('title_question')}
                   </Typography>

                   <Typography variant="h2" fontSize="24px" color={appPrimaryColor} fontWeight={600} sx={{textDecoration: 'underline',  pl: matches ? 0 : 2, pr: matches ? 0 : 2, pt: matches ? 0 : 2}}>
                       {t('uplumi_agency')}
                   </Typography>
               </Box>
               <Box display={matches ? 'flex' : 'grid'} sx={{boxShadow: 3}} alignContent="start">
                   <Box display="grid" width={matches ? '50%' : '100%'} sx={{background: 'white' }} padding={matches ? 4 : 0} gap={4}>
                       <Typography variant="h2" fontSize="20px" color={appPrimaryColor} fontWeight={600} sx={{textDecoration: 'underline', pl: matches ? 0 : 2, pr: matches ? 0 : 2, pt: matches ? 0 : 2}}>
                           {t('work_together')}
                       </Typography>

                       <Typography paragraph fontWeight={500} sx={{pl: matches ? 0 : 2, pr: matches ? 0 : 2}}>
                           {t('who_we_are')}
                       </Typography>

                       <Box display="flex" justifyContent="center" sx={{mb: matches ? 0 : 1}}>
                           <Button onClick={() => window.scrollTo({left: 0, top: 3550, behavior: 'smooth'})} variant="contained" sx={{maxWidth: 175, height: 40, textTransform: 'none', backgroundColor: '#e65100'}}>
                               {t('get_in_touch')}
                            </Button>
                       </Box>
                   </Box>

                   <Box display="grid" width={matches ? '50%' : '100%'} sx={{background: '#e65100' }} padding={matches ? 4 : 0} gap={4}>
                       <Typography variant="h2" fontSize="20px" color="white" fontWeight={600} sx={{textDecoration: 'underline', pl: matches ? 0 : 2, pr: matches ? 0 : 2, pt: matches ? 0 : 2}}>
                           Marketing Audit
                       </Typography>

                       <Typography paragraph fontWeight={500} color="white" sx={{pl: matches ? 0 : 2, pr: matches ? 0 : 2}}>
                           {t('appointment')}
                       </Typography>

                       <Box display="flex" justifyContent="center" sx={{mb: matches ? 0 : 1}}>
                           <Button onClick={() => window.scrollTo({left: 0, top: 1850, behavior: 'smooth'})} variant="contained" sx={{maxWidth: 175, textTransform: 'none', backgroundColor: 'white', color: 'black', '&:hover':{color: 'white'}, height: 40}}>
                               {t('schedule_meeting')}
                           </Button>
                       </Box>
                   </Box>
               </Box>
           </Box>
           <Box alignContent="start" display="grid" width={matches ? '40%' : '100%'} sx={{boxShadow: 3, background: appPrimaryColor }} padding={matches ? 4 : 0} gap={4}>
                <Typography variant="h2" fontSize="20px" color="white" fontWeight={600} sx={{textDecoration: 'underline',  pl: matches ? 0 : 2, pr: matches ? 0 : 2, pt: matches ? 0 : 2}}>
                    {t('what_we_can_help_with')}
                </Typography>

               <Box display="grid" sx={{pl: matches ? 0 : 2, pr: matches ? 0 : 2}}>
                   <Typography paragraph fontWeight={500} color="white">
                       <CheckBoxIcon sx={{fill: '#cfd8dc', alignItems: 'center'}}/> {t('more_sales')}
                   </Typography>

                   <Typography paragraph fontWeight={500} color="white">
                       <CheckBoxIcon sx={{fill: '#cfd8dc'}}/> {t('more_customers')}
                   </Typography>

                   <Typography paragraph fontWeight={500} color="white">
                       <CheckBoxIcon sx={{fill: '#cfd8dc'}}/> {t('higher_rank')}
                   </Typography>

                   <Typography paragraph fontWeight={500} color="white">
                       <CheckBoxIcon sx={{fill: '#cfd8dc'}}/> {t('average_value')}
                   </Typography>

                   <Typography paragraph fontWeight={500} color="white">
                       <CheckBoxIcon sx={{fill: '#cfd8dc'}}/> {t('increased_value')}
                   </Typography>
               </Box>
           </Box>
       </Box>
    )
}

export default AuditBenefits
