import React, {useEffect, useState} from 'react'
import {Alert, AlertColor, Box, Checkbox, Snackbar, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';
import {appSecondaryColor} from "../../../constants";
import emailjs from '@emailjs/browser';
import {useTranslation} from "react-i18next";

const formInitialValue = {
    fullName: '',
    companyName: '',
    email: '',
    phone: '',
    link: '',
    message: ''
}

const Form = () => {
    const {t} = useTranslation(null, {keyPrefix: "contact_form"})
    const [checkBox, setCheckBox] = useState(false)
    const [isSubmitBtnDisable, setIsSubmitBtnDisable] = useState(true)
    const [formData, setFormData] = useState(formInitialValue)
    const [snackBar, setSnackBar] = useState({open: false, message: '', severity: 'success'})

    useEffect(() => {
        const isDisabled = !checkBox || !formData.fullName || !formData.companyName || !formData.email || !formData.phone || !formData.link || !formData.message
        setIsSubmitBtnDisable(isDisabled)
    }, [formData, checkBox])

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBar((prevState) => ({
            ...prevState,
            open: false
        }))
    };

    const handleOnChange = (e: any) => {
        e.preventDefault()

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleOnClick = (e: any) => {
        e.preventDefault()

        emailjs.send(String(process.env.REACT_APP_EMAILJS_SERVICE_ID), String(process.env.REACT_APP_EMAILJS_TEMPLATE_ID), formData, String(process.env.REACT_APP_EMAILJS_PUBLIC_KEY))
            .then((result) => {
                setFormData(formInitialValue)
                setSnackBar({
                    open: true,
                    message: "Message successfully sent!",
                    severity: "success"
                })
            }, (error) => {
                setSnackBar({
                    open: true,
                    message: "Something went wrong!",
                    severity: "error"
                })
                setFormData(formInitialValue)
            });
    }

    return (
        <>
            <Box display="flex" gap={2}>
               <TextField value={formData.fullName} fullWidth placeholder="Full Name" size="small" name="fullName" onChange={handleOnChange}/>
               <TextField value={formData.companyName} fullWidth placeholder="Company Name" size="small" name="companyName" onChange={handleOnChange}/>
            </Box>

            <Box display="flex" gap={2}>
                <TextField value={formData.email} fullWidth placeholder="Email" size="small" name="email" onChange={handleOnChange}/>
                <TextField value={formData.phone} fullWidth placeholder="Phone Number" size="small" name="phone" onChange={handleOnChange}/>
            </Box>

            <TextField value={formData.link} fullWidth placeholder="Link site or Facebook / Instagram / Linkedin" size="small" name="link" onChange={handleOnChange}/>

            <TextField value={formData.message} fullWidth placeholder="Message" multiline rows={4} size="small" name="message" onChange={handleOnChange}/>

            <Box display="flex">
                <Checkbox checked={checkBox} onClick={() => setCheckBox(!checkBox)} sx={{padding: 0, mr: 1}}/>

                <Typography>
                    {t('consent')} <strong>{t('consent_strong')}</strong>
                </Typography>
            </Box>

            <Button disabled={isSubmitBtnDisable} variant="contained" size="medium" startIcon={<SendIcon />} sx={{maxWidth: 150, textTransform: 'none', backgroundColor: appSecondaryColor}} onClick={handleOnClick}>
                {t('submit')}
            </Button>

            <Typography>
                {t('fill_out_form')}
            </Typography>

            <Snackbar open={snackBar.open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{horizontal: "right", vertical: "bottom"}}>
                <Alert onClose={handleClose} severity={snackBar.severity as AlertColor} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Form
