import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { HelmetProvider } from "react-helmet-async";
import './i18n';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const theme = createTheme({
    palette: {
        primary: {
            main: '#1E3C52',
        }
    },
});

root.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
          <HelmetProvider>
              <App />
          </HelmetProvider>
      </ThemeProvider>
  </React.StrictMode>
);
