import React from 'react'
import {Box, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";

const TermsAndConditionsSection = () => {
    const {t} = useTranslation(null, {keyPrefix: "privacy_policy"})
    const matches = useMediaQuery('(min-width:750px)')

    return (
        <Box display="grid" gap={2} paddingBottom={5} paddingLeft={matches ? 20 : 6} paddingRight={matches ? 20 : 6}>
            <Typography paragraph variant="body1">
                {t('privacy_policy_first')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('privacy_policy_second')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('privacy_policy_third')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('privacy_policy_forth')}
            </Typography>

            <Typography variant="h2" fontWeight="bold" fontSize="24px">
                1. {t('we_collect')}
            </Typography>

            <Typography paragraph variant="body1">
                &#x2022; {t('name_surname')}<br/>
                &#x2022; {t('phone_number')}<br/>
                &#x2022; {t('email')}<br/>
                &#x2022; {t('field_work')}<br/>
                &#x2022; {t('city')}<br/>
                &#x2022; {t('address')}<br/>
                &#x2022; {t('info')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('we_collect_first')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('we_collect_second')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('we_collect_third')}
            </Typography>

            <Typography variant="h2" fontWeight="bold" fontSize="24px">
                2. {t('purpose')}
            </Typography>

            <Typography paragraph variant="body1">
                &#x2022; {t('purpose_first')}
                &#x2022; {t('purpose_second')}
                &#x2022; {t('purpose_third')}
                &#x2022; {t('purpose_forth')}
                &#x2022; {t('purpose_fifth')}
                &#x2022; {t('purpose_sixth')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('use')}
            </Typography>

            <Typography paragraph variant="body1">
                &#x2022; {t('use_first')}<br/>
                &#x2022; {t('use_second')}<br/>
                &#x2022; {t('use_third')}<br/>
                &#x2022; {t('use_forth')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('use_fifth')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('use_sixth')}
            </Typography>

            <Typography paragraph variant="body1">
                &#x2022; {t("use_seventh")}<br/>
                &#x2022; {t('use_eigth')}<br/>
                &#x2022; {t('use_ninth')}<br/>
                &#x2022; {t('use_tenth')}<br/>
            </Typography>

            <Typography variant="h2" fontWeight="bold" fontSize="24px">
                3. {t('third_party')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('third_party_first')}
            </Typography>

            <Typography paragraph variant="body1">
                1. {t('third_party_second')}
            </Typography>

            <Typography paragraph variant="body1">
                2. {t('third_party_third')}
            </Typography>

            <Typography variant="h2" fontWeight="bold" fontSize="24px">
                4. {t('rights')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('rights_first')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('rights_second')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('rights_third')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('rights_forth')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('rights_fifth')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('rights_sixth')}
            </Typography>

            <Typography variant="h2" fontWeight="bold" fontSize="24px">
                5. {t('compliant')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('compliant_first')}
            </Typography>

            <Typography variant="h2" fontWeight="bold" fontSize="24px">
                6. {t('updates')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('updates_first')}
            </Typography>
        </Box>
    )
}

export default TermsAndConditionsSection
