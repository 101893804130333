import React from 'react'
import {Box, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../constants";

const GoogleAdsBlog = () => {
    const {t} = useTranslation(null, {keyPrefix: "blogs.google_ads_blog"})
    const matches = useMediaQuery('(min-width:650px)')
    const navigate = useNavigate()

    return (
        <Box display="flex" width={matches ? '50%': '80%'} margin="auto" sx={{ mt: 2, mb: 2 }}>
            <Box display="grid" gap={2}>
                <Typography variant="h2" fontSize="32px" fontWeight="bold">{t('title', {date: new Date().getFullYear()})}</Typography>

                <Box display="grid" gap={0.5}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('introduction')}</Typography>
                    <Typography paragraph>{t('introduction_p1')}</Typography>
                </Box>

                <Box display="grid" gap={1}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('power_of_google_ads')}</Typography>

                    <Typography>{t('power_of_google_ads_p2')}</Typography>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('power_of_google_ads_p3')}</Typography>
                        <Typography paragraph>{t('power_of_google_ads_p4')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('power_of_google_ads_p5')}</Typography>
                        <Typography paragraph>{t('power_of_google_ads_p6')}</Typography>
                        <Typography paragraph>{t('power_of_google_ads_p7')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('power_of_google_ads_p8')}</Typography>
                        <Typography paragraph>{t('power_of_google_ads_p9')}</Typography>

                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('power_of_google_ads_p10')}</Typography>
                        <Typography paragraph>{t('power_of_google_ads_p11')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('power_of_google_ads_p12')}</Typography>
                        <Typography paragraph>{t('power_of_google_ads_p13')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('power_of_google_ads_p14')}</Typography>
                        <Typography paragraph>{t('power_of_google_ads_p15')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('power_of_google_ads_p16')}</Typography>
                        <Typography paragraph>{t('power_of_google_ads_p17')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('power_of_google_ads_p18')}</Typography>
                        <Typography paragraph>{t('power_of_google_ads_p19')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('power_of_google_ads_p20')}</Typography>
                        <Typography paragraph>{t('power_of_google_ads_p21')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('power_of_google_ads_p22')}</Typography>
                        <Typography paragraph>{t('power_of_google_ads_p23')}</Typography>
                    </Box>
                </Box>

                <Box display="grid" gap={1}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('choosing_campaign_type')}</Typography>
                    <Typography paragraph>{t('choosing_campaign_type_p1')}</Typography>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('choosing_campaign_type_p2')}</Typography>
                        <Typography paragraph>{t('choosing_campaign_type_p3')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('choosing_campaign_type_p4')}</Typography>
                        <Typography paragraph>{t('choosing_campaign_type_p5')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('choosing_campaign_type_p6')}</Typography>
                        <Typography paragraph>{t('choosing_campaign_type_p7')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('choosing_campaign_type_p8')}</Typography>
                        <Typography paragraph>{t('choosing_campaign_type_p9')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('choosing_campaign_type_p10')}</Typography>
                        <Typography paragraph>{t('choosing_campaign_type_p11')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('choosing_campaign_type_p12')}</Typography>
                        <Typography paragraph>{t('choosing_campaign_type_p13')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('choosing_campaign_type_p14')}</Typography>
                        <Typography paragraph>{t('choosing_campaign_type_p15')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('choosing_campaign_type_p17')}</Typography>
                        <Typography paragraph>{t('choosing_campaign_type_p18')}</Typography>
                    </Box>
                </Box>

                <Box display="grid" gap={1}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('right_keywords')}</Typography>
                    <Typography paragraph>{t('right_keywords_p1')}</Typography>
                    <Typography paragraph>{t('right_keywords_p2')}</Typography>
                    <Typography paragraph>{t('right_keywords_p3')}</Typography>
                    <Typography paragraph>{t('right_keywords_p4')}</Typography>
                    <Typography paragraph>{t('right_keywords_p5')}</Typography>
                    <Typography paragraph>{t('right_keywords_p6')}</Typography>
                </Box>

                <Box display="grid" gap={1}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('bidding')}</Typography>
                    <Typography paragraph>{t('bidding_p1')}</Typography>
                    <Typography paragraph>{t('bidding_p2')}</Typography>
                    <Typography paragraph>{t('bidding_p3')}</Typography>
                    <Typography paragraph>{t('bidding_p4')}</Typography>
                    <Typography paragraph>{t('bidding_p5')}</Typography>
                    <Typography paragraph>{t('bidding_p6')}</Typography>
                    <Typography paragraph>{t('bidding_p7')}</Typography>
                    <Typography paragraph>{t('bidding_p8')}</Typography>
                    <Typography paragraph>{t('bidding_p9')}</Typography>
                    <Typography paragraph>{t('bidding_p10')}</Typography>
                </Box>

                <Box display="grid" gap={1}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('bid_adjustments')}</Typography>
                    <Typography paragraph>{t('bid_adjustments_p1')}</Typography>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('bid_adjustments_p2')}</Typography>
                        <Typography paragraph>{t('bid_adjustments_p3')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('bid_adjustments_p4')}</Typography>
                        <Typography paragraph>{t('bid_adjustments_p5')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('bid_adjustments_p6')}</Typography>
                        <Typography paragraph>{t('bid_adjustments_p7')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('bid_adjustments_p8')}</Typography>
                        <Typography paragraph>{t('bid_adjustments_p9')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('bid_adjustments_p10')}</Typography>
                        <Typography paragraph>{t('bid_adjustments_p11')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('bid_adjustments_p12')}</Typography>
                        <Typography paragraph>{t('bid_adjustments_p13')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('bid_adjustments_p14')}</Typography>
                        <Typography paragraph>{t('bid_adjustments_p15')}</Typography>
                    </Box>

                    <Box display="grid" gap={0.5}>
                        <Typography variant="h3" fontSize="20px" fontWeight="bold">{t('bid_adjustments_p16')}</Typography>
                        <Typography paragraph>{t('bid_adjustments_p17')}</Typography>
                    </Box>

                    <Typography>{t('bid_adjustments_p18')}</Typography>
                </Box>

                <Box display="grid" gap={1}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('tracking')}</Typography>
                    <Typography paragraph>{t('tracking_p1')}</Typography>
                    <Typography paragraph>{t('tracking_p2')}</Typography>
                    <Typography paragraph>{t('tracking_p3')}</Typography>
                    <Typography paragraph>{t('tracking_p4')}</Typography>
                    <Typography paragraph>{t('tracking_p5')}</Typography>
                    <Typography paragraph>{t('tracking_p6')}</Typography>
                    <Typography paragraph>{t('tracking_p7')}</Typography>
                    <Typography paragraph>{t('tracking_p8')}</Typography>
                    <Typography paragraph>{t('tracking_p9')}</Typography>
                    <Typography paragraph>{t('tracking_p10')}</Typography>
                </Box>

                <Box display="grid" gap={1}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('testing')}</Typography>
                    <Typography paragraph>{t('testing_p2')}</Typography>
                    <Typography paragraph>{t('testing_p3')}</Typography>
                    <Typography paragraph>{t('testing_p4')}</Typography>
                    <Typography paragraph>{t('testing_p5')}</Typography>
                    <Typography paragraph>{t('testing_p6')}</Typography>
                    <Typography paragraph>{t('testing_p7')}</Typography>
                    <Typography paragraph>{t('testing_p8')}</Typography>
                    <Typography paragraph>{t('testing_p9')}</Typography>
                    <Typography paragraph>{t('testing_p10')}</Typography>
                </Box>

                <Box display="grid" gap={1}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('remarketing')}</Typography>
                    <Typography paragraph>{t('remarketing_p1')}</Typography>
                    <Typography paragraph>{t('remarketing_p2')}</Typography>
                    <Typography paragraph>{t('remarketing_p3')}</Typography>
                    <Typography paragraph>{t('remarketing_p4')}</Typography>
                    <Typography paragraph>{t('remarketing_p5')}</Typography>
                    <Typography paragraph>{t('remarketing_p6')}</Typography>
                    <Typography paragraph>{t('remarketing_p7')}</Typography>
                    <Typography paragraph>{t('remarketing_p8')}</Typography>
                    <Typography paragraph>{t('remarketing_p9')}</Typography>
                    <Typography paragraph>{t('remarketing_p10')}</Typography>
                </Box>

                <Box display="grid" gap={1}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('google_and_seo')}</Typography>
                    <Typography paragraph>{t('google_and_seo_p1')}</Typography>
                    <Typography paragraph>{t('google_and_seo_p2')}</Typography>
                    <Typography paragraph>{t('google_and_seo_p3')}</Typography>
                    <Typography paragraph>{t('google_and_seo_p4')}</Typography>
                    <Typography paragraph>{t('google_and_seo_p5')}</Typography>
                    <Typography paragraph>{t('google_and_seo_p6')}</Typography>
                    <Typography paragraph>{t('google_and_seo_p7')}</Typography>
                </Box>

                <Box display="grid" gap={1}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('measure_roi')}</Typography>
                    <Typography paragraph>{t('measure_roi_p1')}</Typography>
                </Box>

                <Box display="grid" gap={1}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('conclusion')}</Typography>
                    <Typography paragraph>{t('conclusion_p1')}</Typography>
                    <Typography paragraph>{t('conclusion_p2')}</Typography>
                </Box>

                <Box display="grid" gap={1}>
                    <Typography paragraph>{t('design_rush_promo')}<a href="https://www.designrush.com/agency/paid-media-pay-per-click/trends/ppc-ads">PPC Ads</a></Typography>
                </Box>

                <Button onClick={() => navigate(ROUTES.blogs)} startIcon={<KeyboardBackspaceIcon />} sx={{textTransform: 'none', fontWeight: 'bold', fontSize: '20px', margin: 'auto', maxWidth: '300px'}}>
                    {t('back_to_blog')}
                </Button>
            </Box>
        </Box>
    )
}

export default GoogleAdsBlog
