import React from 'react'
import {useTranslation} from "react-i18next";
import {Box, Typography, useMediaQuery} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {ROUTES} from "../../constants";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const BestSiteForOnlineStoreBlog = () => {
    const {t} = useTranslation(null, {keyPrefix: "blogs.best_site_for_online_store"})
    const matches = useMediaQuery('(min-width:650px)')
    const navigate = useNavigate()

    return (
        <Box display="flex" width={matches ? '50%': '80%'} margin="auto" sx={{ mt: 2, mb: 2 }}>
            <Box display="grid" gap={1}>
                <Typography variant="h2" fontSize="32px" fontWeight="bold">{t('title')}</Typography>

                <Typography paragraph>{t('title_p1')}</Typography>

                <Box display="grid" gap={0.5}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('introduction')}</Typography>
                    <Typography paragraph>{t('introduction_p1')}</Typography>
                </Box>

                <Typography paragraph>{t('understanding_needs')}</Typography>

                <Box display="grid" gap={0.5}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('niche')}</Typography>
                    <Typography paragraph>{t('niche_p1')}</Typography>
                </Box>

                <Box display="grid" gap={0.5}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('budget')}</Typography>
                    <Typography paragraph>{t('budget_p1')}</Typography>
                </Box>

                <Box display="grid" gap={0.5}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('technical')}</Typography>
                    <Typography paragraph>{t('technical_p1')}</Typography>
                </Box>

                <Box display="grid" gap={0.5}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('scalability')}</Typography>
                    <Typography paragraph>{t('scalability_p1')}</Typography>
                </Box>

                <Box display="grid" gap={0.5}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('top_plat')}</Typography>
                    <Typography paragraph>{t('top_plat_p1')}</Typography>
                </Box>

                <Box display="grid" gap={0.5}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('shopify')}</Typography>
                    <Typography paragraph>{t('shopify_p1')}</Typography>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('shopify_pros')}</Typography>
                    <Typography paragraph>{t('shopify_pros_p1')}</Typography>
                    <Typography paragraph>{t('shopify_pros_p2')}</Typography>
                    <Typography paragraph>{t('shopify_pros_p3')}</Typography>
                    <Typography paragraph>{t('shopify_pros_p4')}</Typography>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('shopify_cons')}</Typography>
                    <Typography paragraph>{t('shopify_cons_p1')}</Typography>
                </Box>

                <Box display="grid" gap={0.5}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('woocommerce')}</Typography>
                    <Typography paragraph>{t('woocommerce_p1')}</Typography>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('woocommerce_pros')}</Typography>
                    <Typography paragraph>{t('woocommerce_pros_p1')}</Typography>
                    <Typography paragraph>{t('woocommerce_pros_p2')}</Typography>
                    <Typography paragraph>{t('woocommerce_pros_p3')}</Typography>
                    <Typography paragraph>{t('woocommerce_pros_p4')}</Typography>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('woocommerce_cons')}</Typography>
                    <Typography paragraph>{t('woocommerce_cons_p1')}</Typography>
                    <Typography paragraph>{t('woocommerce_cons_p2')}</Typography>
                    <Typography paragraph>{t('woocommerce_cons_p3')}</Typography>
                </Box>

                <Box display="grid" gap={0.5}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('bigcommerce')}</Typography>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('bigcommerce_pros')}</Typography>
                    <Typography paragraph>{t('bigcommerce_p1')}</Typography>
                    <Typography paragraph>{t('bigcommerce_p2')}</Typography>
                    <Typography paragraph>{t('bigcommerce_p3')}</Typography>
                    <Typography paragraph>{t('bigcommerce_p4')}</Typography>
                    <Typography paragraph>{t('bigcommerce_p5')}</Typography>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('bigcommerce_cons')}</Typography>
                    <Typography paragraph>{t('bigcommerce_cons_p1')}</Typography>
                    <Typography paragraph>{t('bigcommerce_cons_p2')}</Typography>
                </Box>


                <Box display="grid" gap={0.5}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('wix')}</Typography>
                    <Typography paragraph>{t('wix_p1')}</Typography>
                    <Typography paragraph>{t('wix_p2')}</Typography>
                    <Typography paragraph>{t('wix_p3')}</Typography>
                </Box>

                <Box display="grid" gap={0.5}>
                    <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('conclusion')}</Typography>
                    <Typography paragraph>{t('conclusion_p1')}</Typography>
                    <Typography paragraph>{t('conclusion_p2')}</Typography>
                </Box>

                <Typography fontSize="24px" variant="h2" fontWeight="bold">{t('faqs')}</Typography>

                <Box display="grid" gap={0.5}>
                    <Typography variant="h2" fontSize="20px" fontWeight="bold">{t('faqs_1')}</Typography>
                    <Typography paragraph>{t('faqs_1_ans')}</Typography>
                </Box>

                <Box display="grid" gap={0.5}>
                    <Typography variant="h2" fontSize="20px" fontWeight="bold">{t('faqs_2')}</Typography>
                    <Typography paragraph>{t('faqs_2_ans')}</Typography>
                </Box>

                <Box display="grid" gap={0.5}>
                    <Typography variant="h2" fontSize="20px" fontWeight="bold">{t('faqs_3')}</Typography>
                    <Typography paragraph>{t('faqs_3_ans')}</Typography>
                </Box>

                <Box display="grid" gap={0.5}>
                    <Typography variant="h2" fontSize="20px" fontWeight="bold">{t('faqs_4')}</Typography>
                    <Typography paragraph>{t('faqs_4_ans')}</Typography>
                </Box>

                <Box display="grid" gap={0.5}>
                    <Typography variant="h2" fontSize="20px" fontWeight="bold">{t('faqs_5')}</Typography>
                    <Typography paragraph>{t('faqs_5_ans')}</Typography>
                </Box>

                <Button onClick={() => navigate(ROUTES.blogs)} startIcon={<KeyboardBackspaceIcon />} sx={{textTransform: 'none', fontWeight: 'bold', fontSize: '20px', margin: 'auto', maxWidth: '300px'}}>
                    {t('back_to_blog')}
                </Button>
            </Box>
        </Box>
    )
}

export default BestSiteForOnlineStoreBlog
