import React from 'react'
import { Box, Typography } from "@mui/material";
import { appPrimaryColor, appSecondaryColor } from "../../../../constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTranslation} from "react-i18next";

const AuditDescription = () => {
    const {t} = useTranslation(null, {keyPrefix: "contact_page"})
    const matches = useMediaQuery('(min-width:500px)');

    return (
        <>
            <Box display="grid">
                <Typography sx={{ color: appSecondaryColor, fontWeight: 'bold' }} fontSize={ matches ? '24px' : '18px' } variant="h2">
                    {t('schedule_call')}
                </Typography>
                <Typography sx={{ color: appPrimaryColor, fontWeight: 'bold' }} fontSize={ matches ? '36px' : '28px' } variant="h2">
                    {t('free_demo_call')}
                </Typography>
            </Box>


            <Typography
                sx={{ color: appPrimaryColor, width: matches ? '75%' : '90%', marginBlockStart: 0, marginBlockEnd: 0 }}
                display="block"
                paragraph
                fontSize={ matches ? '20px' : '16px' }
            >
                {t('after_call')}
            </Typography>
        </>
    )
}
export default AuditDescription
