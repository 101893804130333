import React, {useEffect} from 'react'
import { Toolbar, Tab, Tabs, Box, AppBar, IconButton } from "@mui/material";
import { appPrimaryColor, facebookColor, navBarBackgroundColor, ROUTES } from "../../constants";
import { useLocation, useNavigate} from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import SandwichMenu from "../SandwichMenu";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { motion } from 'framer-motion'
import {useTranslation} from "react-i18next";

const Navbar = () => {
    const {t} = useTranslation(null, {keyPrefix: "navbar"})
    const location = useLocation()
    const navigate = useNavigate()
    const [value, setValue] = React.useState(0);
    const matches = useMediaQuery('(min-width:890px)');

    useEffect(() => {
        const path = location.pathname

        // if(path.split('/').length > 2) {
        //     navigate(path.split('/')[1])
        // }

        setValue(
            path === ROUTES.home ? 0 :
            path === ROUTES.offer ? 1 :
            path === ROUTES.team ? 2 :
            path === ROUTES.contact ? 3 :
            path === ROUTES.blogs || path === ROUTES.secrets_of_digital_marketing_blog || path === ROUTES.google_ads_blog || path === ROUTES.best_site_for_online_store_blog ? 4 : 0
        )
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <motion.div style={{zIndex: 10}} animate={{ y: 0 }} initial={{ y: -100 }} transition={{ type: 'tween', duration: 1 }}>
            <AppBar component="nav"
                    position="sticky"
                    sx={{
                        width: 'auto',
                        height: 'auto',
                        backgroundColor: navBarBackgroundColor,
                        border: `1px solid ${navBarBackgroundColor}`,
                        borderRadius: 5,
                        margin: 2,
                    }}
            >
                <Toolbar disableGutters>
                    {
                        matches ?
                            <Box
                                display="grid"
                                gridTemplateColumns="100px 1fr 200px"
                                gridTemplateRows="repeat(1, max-content)"
                                width="100%"
                            >
                                <img
                                    style={{ margin: 'auto', cursor: 'pointer' }}
                                    width="75%"
                                    height="auto"
                                    title="Company logo"
                                    loading="eager"
                                    alt="Agency logo containing company initials"
                                    src={require('../../resources/images/marketing agency logo.png')}
                                    onClick={() => navigate(ROUTES.home)}
                                />

                                <Tabs
                                    sx={{ gridColumn: '2/3', margin: 'auto' }}
                                    value={value}
                                    onChange={handleChange}
                                    TabIndicatorProps={{
                                        sx:{
                                            height: 5,
                                            borderRadius: 5,
                                        }
                                    }}
                                >
                                    <Tab label={t('home')} disableTouchRipple onClick={() => navigate(ROUTES.home)} sx={{ margin: 1, fontWeight: 'bold', color: appPrimaryColor, textTransform: 'none' }}/>

                                    <Tab label={t('services')} onClick={() => navigate(ROUTES.offer)} sx={{ margin: 1, fontWeight: 'bold', color: appPrimaryColor, textTransform: 'none' }}/>

                                    <Tab label={t('meet_the_team')} onClick={() => navigate(ROUTES.team)} sx={{ margin: 1, fontWeight: 'bold', color: appPrimaryColor, textTransform: 'none' }}/>

                                    <Tab label={t('contact')} onClick={() => navigate(ROUTES.contact)} sx={{ margin: 1, fontWeight: 'bold', color: appPrimaryColor, textTransform: 'none' }}/>

                                    <Tab label={t('blogs')} onClick={() => navigate(ROUTES.blogs)} sx={{ margin: 1, fontWeight: 'bold', color: appPrimaryColor, textTransform: 'none' }}/>
                                </Tabs>

                                <Box display="flex" sx={{ gridColumn: '3/4', margin: 'auto' }}>
                                    <IconButton href='https://www.facebook.com/profile.php?id=100087973156286'>
                                        <FacebookIcon sx={{ color: facebookColor }}/>
                                    </IconButton>

                                    <IconButton href='https://www.instagram.com/uplumi.marketing/'>
                                        <InstagramIcon sx={{ color: '#f44336' }}/>
                                    </IconButton>

                                    <IconButton href='https://www.linkedin.com/company/uplumi-marketing'>
                                        <LinkedInIcon sx={{color: '#0e76a8'}} />
                                    </IconButton>

                                    {/*<IconButton href='https://www.upwork.com/agencies/1605275091716059136/'>*/}
                                    {/*    <SiUpwork style={{color: '#6fda44'}}/>*/}
                                    {/*</IconButton>*/}
                                </Box>
                            </Box>
                            :
                            <Box
                                display="grid"
                                gridTemplateColumns="100px 1fr 60px"
                                gridTemplateRows="repeat(3, max-content)"
                                width="100%"
                            >
                                <img
                                    style={{ margin: 'auto', cursor: 'pointer' }}
                                    width="75%"
                                    height="auto"
                                    title="Company logo"
                                    loading="eager"
                                    alt="Agency logo containing company initials"
                                    src={require('../../resources/images/marketing agency logo.png')}
                                    onClick={() => navigate(ROUTES.home)}
                                />

                                <Box display="flex" justifyContent="center" sx={{ gridColumn: '2/3', width: '100%', margin: 'auto' }} >
                                    <IconButton href='https://www.facebook.com/profile.php?id=100087973156286'>
                                        <FacebookIcon sx={{ color: facebookColor }}/>
                                    </IconButton>

                                    <IconButton href='https://www.instagram.com/uplumi.marketing/'>
                                        <InstagramIcon sx={{ color: '#f44336' }}/>
                                    </IconButton>

                                    <IconButton href='https://www.linkedin.com/company/uplumi-marketing'>
                                        <LinkedInIcon sx={{color: '#0e76a8'}} />
                                    </IconButton>

                                    {/*<IconButton href='https://www.upwork.com/agencies/1605275091716059136/'>*/}
                                    {/*    <SiUpwork style={{color: '#6fda44'}}/>*/}
                                    {/*</IconButton>*/}
                                </Box>

                                <Box display="flex" justifyContent="end" style={{ gridColumn: '3/4', width: '100%', margin: 'auto' }}>
                                    <SandwichMenu />
                                </Box>
                            </Box>
                    }
                </Toolbar>
            </AppBar>
        </motion.div>

    )
}

export default Navbar
