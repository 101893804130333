import React from 'react'
import {Box, Grid, Link, Typography} from "@mui/material";
import {appSecondaryColor, ROUTES} from "../../../constants";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const CompanySection = () => {
    const {t} = useTranslation(null, {keyPrefix: "footer"})
    const navigate = useNavigate()

    return (
        <Grid item xs={12} sm={6} md={6} lg={2} sx={{my: 4}}>
            <Box display="grid" gap={1}>
                <Typography variant="h2" fontSize="26px" fontWeight="bold" color={appSecondaryColor}>
                    {t('company')}
                </Typography>

                <Link onClick={() => navigate(ROUTES.team)} sx={{'&:hover': {cursor: 'pointer', textDecoration: 'underline', textDecorationColor: 'white'}}}>
                    <Typography color="white" fontSize="16px">
                        {t('about_us')}
                    </Typography>
                </Link>

                <Link sx={{'&:hover': {cursor: 'pointer', textDecoration: 'underline', textDecorationColor: 'white'}}}>
                    <Typography color="white" fontSize="16px">
                        {t('blog')}
                    </Typography>
                </Link>

                <Link onClick={() => navigate(ROUTES.contact)} sx={{'&:hover': {cursor: 'pointer', textDecoration: 'underline', textDecorationColor: 'white'}}}>
                    <Typography color="white" fontSize="16px">
                        {t('contact')}
                    </Typography>
                </Link>
            </Box>
        </Grid>
    )
}

export default CompanySection
