import React from 'react'
import {Box, Typography, useMediaQuery} from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {appSecondaryColor} from "../../constants";
import AuditButton from "../Button";
import {useTranslation} from "react-i18next";

const WhyUplumi = () => {
    const {t} = useTranslation(null, {keyPrefix: "home_why_uplumi"})
    const matches = useMediaQuery('(min-width:1000px)')

    return (
        <Box display={matches ? 'flex' : 'grid'} justifyContent="center" padding={5} sx={{maxWidth: '100vw', background: '#1B4059'}} gap={5}>
            <Box display="grid" width={matches ? '45%' : '90%'} gap={2} margin="auto">
                <Typography color="white" variant="h2" fontSize="28px" fontWeight="bold">
                    {t('title_question')}
                </Typography>

                <Box display="flex">
                    <CheckBoxIcon sx={{fill: appSecondaryColor,  mr: 1}}/>

                    <Typography color="white">
                        {t('first_paragraph')}
                    </Typography>
                </Box>

                <Box display="flex">
                    <CheckBoxIcon sx={{fill: appSecondaryColor,  mr: 1}}/>

                    <Typography color="white">
                        {t('second_paragraph')}
                    </Typography>
                </Box>

                <Box display="flex">
                    <CheckBoxIcon sx={{fill: appSecondaryColor,  mr: 1}}/>

                    <Typography color="white">
                        {t('third_paragraph')}
                    </Typography>
                </Box>

                <Box display="flex">
                    <CheckBoxIcon sx={{fill: appSecondaryColor,  mr: 1}}/>

                    <Typography color="white">
                        {t('forth_paragraph')}
                    </Typography>
                </Box>

                <Box display="flex">
                    <CheckBoxIcon sx={{fill: appSecondaryColor,  mr: 1}}/>

                    <Typography color="white">
                        {t('fifth_paragraph')}
                    </Typography>
                </Box>

                <Box display="flex">
                    <CheckBoxIcon sx={{fill: appSecondaryColor,  mr: 1}}/>

                    <Typography color="white">
                        {t('sixth_paragraph')}
                    </Typography>
                </Box>

                <AuditButton />
            </Box>

            <img
                height="auto"
                width="auto"
                title="Laptop showing shopify as a website builder engine"
                loading="eager"
                alt="Laptop showing shopify as a website builder engine"
                src={require('../../resources/images/home9.png')}
                style={{display: "block", width: matches ? '45%' : '100%', maxWidth: matches ? '45%' : '100%', margin: 'auto'}}
            />
        </Box>
    )
}

export default WhyUplumi
