import React from 'react'
import {Box, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../constants";

const DigitalMarketingBlog = () => {
    const {t} = useTranslation(null, {keyPrefix: "blogs.digital_marketing_blog"})
    const matches = useMediaQuery('(min-width:650px)')
    const navigate = useNavigate()

    return (
        <Box display="flex" width={matches ? '50%': '80%'} margin="auto" sx={{ mt: 2, mb: 2 }}>
                <Box display="grid" gap={1}>
                        <Typography variant="h2" fontSize="32px" fontWeight="bold">{t('title')}</Typography>

                        <Typography fontSize="24px" variant="h2" fontWeight="bold">
                                {t('introduction')}
                        </Typography>

                        <Typography paragraph>
                                {t('introduction_p1')}
                        </Typography>

                        <Typography fontSize="24px" variant="h2" fontWeight="bold">
                                {t('understand_audience')}
                        </Typography>

                        <Typography paragraph>
                                {t('understand_audience_p1')}
                        </Typography>

                        <Typography fontSize="24px" variant="h2" fontWeight="bold">
                                {t('objectives')}
                        </Typography>

                        <Typography paragraph>
                                {t('objectives_p1')}
                        </Typography>

                        <Typography fontSize="24px" variant="h2" fontWeight="bold">
                                {t('content')}
                        </Typography>

                        <Typography paragraph>
                                {t('content_p1')}
                        </Typography>

                        <Typography fontSize="24px" variant="h2" fontWeight="bold">
                                {t('seo')}
                        </Typography>

                        <Typography paragraph>
                                {t('seo_p1')}
                        </Typography>

                        <Typography fontSize="24px" variant="h2" fontWeight="bold">
                                {t('social')}
                        </Typography>

                        <Typography paragraph>
                                {t('social_p1')}
                        </Typography>

                        <Typography fontSize="24px" variant="h2" fontWeight="bold">
                                {t('engagement')}
                        </Typography>

                        <Typography paragraph>
                                {t('engagement_p1')}
                        </Typography>

                        <Typography fontSize="24px" variant="h2" fontWeight="bold">
                                {t('email')}
                        </Typography>

                        <Typography paragraph>
                                {t('email_p1')}
                        </Typography>

                        <Typography fontSize="24px" variant="h2" fontWeight="bold">
                                {t('seg_aut')}
                        </Typography>

                        <Typography paragraph>
                                {t('seg_aut_p1')}
                        </Typography>

                        <Typography fontSize="24px" variant="h2" fontWeight="bold">
                                {t('ppc')}
                        </Typography>

                        <Typography paragraph>
                                {t('ppc_p1')}
                        </Typography>

                        <Typography fontSize="24px" variant="h2" fontWeight="bold">
                                {t('social_ads')}
                        </Typography>

                        <Typography paragraph>
                                {t('social_ads_p1')}
                        </Typography>

                        <Typography fontSize="24px" variant="h2" fontWeight="bold">
                                {t('data_driven')}
                        </Typography>

                        <Typography paragraph>
                                {t('data_driven_p1')}
                        </Typography>

                        <Typography fontSize="24px" variant="h2" fontWeight="bold">
                                {t('testing')}
                        </Typography>

                        <Typography paragraph>
                                {t('testing_p1')}
                        </Typography>

                        <Typography fontSize="24px" variant="h2" fontWeight="bold">
                                {t('conclusion')}
                        </Typography>

                        <Typography paragraph>
                                {t('conclusion_p1')}
                        </Typography>

                        <Button onClick={() => navigate(ROUTES.blogs)} startIcon={<KeyboardBackspaceIcon />} sx={{textTransform: 'none', fontWeight: 'bold', fontSize: '20px', margin: 'auto', maxWidth: '300px'}}>
                                {t('back_to_blog')}
                        </Button>
                </Box>
        </Box>
    )
}

export default DigitalMarketingBlog
