import React from 'react'
import {Grid, Typography, useMediaQuery} from "@mui/material";
import { appPrimaryColor, appSecondaryColor } from "../../constants";
import AuditButton from "../Button";
import {TypeAnimation} from "react-type-animation";
import {useTranslation} from "react-i18next";

const HomeTitle = () => {
    const {t} = useTranslation(null, {keyPrefix: "home_title"})
    const matches = useMediaQuery('(min-width:450px)')

    return (
        <Grid item xs={12} sm={8} md={8} lg={5}>
            <Typography fontSize={ matches ? '42px' : '36px' } variant="h1" color={appPrimaryColor} fontWeight="bold" sx={{ mt: 2, mb: 2 }}>
                Uplumi Digital Marketing
            </Typography>

            <Typography paragraph color={appSecondaryColor} fontWeight="bold" fontSize={ matches ? '24px' : '18px' } sx={{ mt: 2, mb: 2 }}>
                {t('what_we_do')}
            </Typography>

            <Typography paragraph color={appPrimaryColor} fontSize={ matches ? '24px' : '18px' } sx={{ mt: 2, mb: 2 }}>
                {t('stop_loosing_quote')}
            </Typography>

            <Typography paragraph color={appSecondaryColor} fontWeight="bold" fontSize={ matches ? '24px' : '18px' } sx={{ mt: 2, mb: 2, margin: 'auto' }}>
                <TypeAnimation
                    sequence={[
                        t('free_marketing_consultation'),
                        t('paid_advertising'),
                        t('search_engine_optimization'),
                        t('email_marketing'),
                        t('website_building'),
                        t('social_media_management')
                    ]}
                    wrapper="span"
                    cursor={true}
                    repeat={Infinity}
                    style={{ fontSize: '1.5em', display: 'inline-block' }}
                    speed={{type: "keyStrokeDelayInMs", value: 150}}
                    omitDeletionAnimation={true}
                />
            </Typography>

            <Typography paragraph color={appPrimaryColor} fontSize={ matches ? '24px' : '18px' } sx={{ mt: 2, mb: 2 }}>
                {t('take_action_text')}
            </Typography>

            <AuditButton />
        </Grid>
    )
}

export default HomeTitle
