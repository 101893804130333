import React from 'react'
import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {appPrimaryColor} from "../../../../constants";

const AuditAvatars = () => {
    return (
        <Box display="flex" justifyContent="start" gap={10}>
            <Avatar sx={{width: 100, height: 100, border: `1px solid ${appPrimaryColor}`}} imgProps={{width: 100, height: 100}} alt="founder Avatar" title="Marketing Specialist Avatar" src={require('../../../../resources/images/founder avatar.png')}/>

            <Avatar sx={{width: 100, height: 100}} imgProps={{width: 100, height: 100}} alt="marketing manager" title="marketing manager" src={require('../../../../resources/images/vali.PNG')}/>
        </Box>
    )
}

export default AuditAvatars
