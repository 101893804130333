import React from 'react'
import {Box} from "@mui/material";
import PrivacyCoverSection from "./PolicyCoverSection";
import PrivacyPolicySection from "./PrivacyPolicySection";

const PrivacyPolicy = () => {

    return (
        <Box display="grid" gap={4} sx={{background: 'white'}}>
            <PrivacyCoverSection />

            <PrivacyPolicySection />
        </Box>
    )
}

export default PrivacyPolicy
