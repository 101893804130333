import React from 'react'
import {Box, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";

const TermsAndConditionsSection = () => {
    const  {t} = useTranslation(null, {keyPrefix: "terms_and_conditions"})
    const matches = useMediaQuery('(min-width:650px)')

    return (
        <Box display="grid" gap={2} paddingBottom={5} paddingLeft={matches ? 20 : 6} paddingRight={matches ? 20 : 6}>
            <Typography paragraph variant="body1">
                {t('by_using')}
            </Typography>

            <Typography variant="h2" fontWeight="bold" fontSize="24px">
                1. {t('copyright_and_trademarks')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('copyright_and_trademarks_text')}
            </Typography>

            <Typography variant="h2" fontWeight="bold" fontSize="24px">
                2. {t('limited_liability')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('limited_liability_first')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('limited_liability_second')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('limited_liability_third')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('limited_liability_forth')}
            </Typography>

            <Typography variant="h2" fontWeight="bold" fontSize="24px">
                3. {t('terms_of_use')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('terms_of_use_first')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('terms_of_use_second')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('terms_of_use_third')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('terms_of_use_forth')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('terms_of_use_fifth')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('terms_of_use_sixth')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('terms_of_use_seventh')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('terms_of_use_eight')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('terms_of_use_ninth')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('terms_of_use_tenth')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('terms_of_use_eleventh')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('terms_of_use_twelve')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('terms_of_use_thirteen')}
            </Typography>

            <Typography variant="h2" fontWeight="bold" fontSize="24px">
                4. {t('third_party')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('third_party_first')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('third_party_second')}
            </Typography>

            <Typography variant="h2" fontWeight="bold" fontSize="24px">
                5. {t('use_of_site')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('use_of_site_first')}
            </Typography>

            <Typography variant="h2" fontWeight="bold" fontSize="24px">
                6. {t('changes')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('changes_first')}
            </Typography>

            <Typography variant="h2" fontWeight="bold" fontSize="24px">
                7. {t('legislation')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('legislation_first')}
            </Typography>

            <Typography variant="h2" fontWeight="bold" fontSize="24px">
                8. {t('personal_data')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('personal_data_first')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('personal_data_second')}
            </Typography>

            <Typography variant="h2" fontWeight="bold" fontSize="24px">
                9. Copyright
            </Typography>

            <Typography paragraph variant="body1">
                {t('copyright_first')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('copyright_second')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('copyright_third')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('copyright_forth')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('copyright_fifth')}
            </Typography>

            <Typography paragraph variant="body1">
                {t('copyright_sixth')}
            </Typography>
        </Box>
    )
}

export default TermsAndConditionsSection
