import React from 'react'
// @ts-ignore
import background from "../../resources/images/freemeeting.png";
import {Box, Typography} from "@mui/material";
import AuditButton from "../Button";
import {appPrimaryColor} from "../../constants";
import {useTranslation} from "react-i18next";

const FreeMeeting = () => {
    const {t} = useTranslation(null, {keyPrefix: "free_meeting"})

    return (
        <Box sx={{ backgroundImage: `url(${background})`}}>
            <Box display="grid" sx={{ background: 'rgba(230, 48, 1, 0.85)'}} gap={2} padding={10} zIndex={1}>
                <Typography variant="h2" fontWeight="500" fontSize="22px" color="white" margin="auto" zIndex={10}>
                    {t('uplumi')}
                </Typography>

                <Typography variant="h2" fontSize="28px" fontWeight="bold" color="white" margin="auto">
                    {t('first_prop')}
                </Typography>

                <Typography variant="h2" fontSize="18px" color="white" margin="auto">
                    {t('schedule_a')} <strong>{t('free')}</strong> {t('45_min_consult')}
                </Typography>

                <Box margin="auto">
                    <AuditButton text={t('i_want_free_meeting')} bgColor={appPrimaryColor}/>
                </Box>
            </Box>
        </Box>
    )
}

export default FreeMeeting
