import React from 'react'
import {Box, useMediaQuery} from "@mui/material";
import AuditCalendly from "../AuditCalendly";

const CalendlySection = () => {
    const matches = useMediaQuery('(min-width:850px)')

    return (
        <Box gap={matches ? 0 : 1} display={matches ? 'flex' : 'grid'} sx={{ maxWidth: '100vw', background: 'white', pb: matches ? 0 : 2 }}>
            <img
                height="100%"
                width={matches ? '50%' : '100%'}
                title="Laptop showing shopify as a website builder engine"
                loading="eager"
                alt="Laptop showing shopify as a website builder engine"
                src={require('../../../resources/images/termsandcond.png')}
                style={{display: "block", margin: 'auto'}}
            />

            <AuditCalendly />
        </Box>
    )
}

export default CalendlySection
