import React from 'react'
import {Box, Grid, Link, Typography} from "@mui/material";
import {appSecondaryColor, ROUTES} from "../../../constants";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ServicesSection = () => {
    const {t} = useTranslation(null, {keyPrefix: "footer"})
    const navigate = useNavigate()

    return (
        <Grid item xs={12} sm={6} md={6} lg={3} sx={{my: 4}}>
            <Box display="grid" gap={1}>
                <Typography variant="h2" fontSize="26px" fontWeight="bold" color={appSecondaryColor}>
                    {t('services')}
                </Typography>

                <Link onClick={() => navigate(ROUTES.offer)} sx={{'&:hover': {cursor: 'pointer', textDecoration: 'underline', textDecorationColor: 'white'}}}>
                    <Typography color="white" fontSize="16px">
                        {t('website_building')}
                    </Typography>
                </Link>

                <Link onClick={() => navigate(ROUTES.offer)} sx={{'&:hover': {cursor: 'pointer', textDecoration: 'underline', textDecorationColor: 'white'}}}>
                    <Typography color="white" fontSize="16px">
                        {t('paid_advertising')}
                    </Typography>
                </Link>

                <Link onClick={() => navigate(ROUTES.offer)} sx={{'&:hover': {cursor: 'pointer', textDecoration: 'underline', textDecorationColor: 'white'}}}>
                    <Typography color="white" fontSize="16px">
                        SEO
                    </Typography>
                </Link>

                <Link onClick={() => navigate(ROUTES.offer)} sx={{'&:hover': {cursor: 'pointer', textDecoration: 'underline', textDecorationColor: 'white'}}}>
                    <Typography color="white" fontSize="16px">
                        Email Marketing
                    </Typography>
                </Link>

                <Link onClick={() => navigate(ROUTES.offer)} sx={{'&:hover': {cursor: 'pointer', textDecoration: 'underline', textDecorationColor: 'white'}}}>
                    <Typography color="white" fontSize="16px">
                        Social Media Management
                    </Typography>
                </Link>

                <Link onClick={() => navigate(ROUTES.offer)} sx={{'&:hover': {cursor: 'pointer', textDecoration: 'underline', textDecorationColor: 'white'}}}>
                    <Typography color="white" fontSize="16px">
                        {t('marketing_consultancy')}
                    </Typography>
                </Link>
            </Box>
        </Grid>
    )
}

export default ServicesSection
