import React from 'react'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {useMediaQuery} from "@mui/material";
import {appPrimaryColor} from "../../../../constants";
import {useTranslation} from "react-i18next";

const ValiAvatar = () => {
    const {t} = useTranslation(null, {keyPrefix: "team"})
    const matches = useMediaQuery('(min-width:850px)')

    return (
        <Card sx={{ maxWidth: 400, width: matches ? 'auto' : '90%', margin: matches ? '0' : 'auto', transition: 'all .4s ease-in-out', '&:hover':{cursor: 'pointer', transform: 'scale(1.05)' } }}>
            <CardHeader
                avatar={
                    <Avatar sx={{width: 75, height: 75}} imgProps={{width: 75, height: 75}} alt="Founder of Uplumi Agency" title="Founder of Uplumi Agency" aria-label="avatar" src={require('../../../../resources/images/vali.PNG')}/>
                }
                title="Valentin"
                subheader="Marketing Specialist"
            />
            <CardMedia
                component="img"
                height="400"
                width="auto"
                src={require('../../../../resources/images/marketing-specialist.png')}
                alt="Marketing specialist"
                title="Marketing specialist"
                loading="eager"
            />
            <CardContent sx={{height: matches ? 140 : 'auto'}}>
                <Typography variant="body2" color={appPrimaryColor}>
                    {t('vali_card_description')}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default ValiAvatar
