import React from 'react'
import TermsAndConditions from "../../components/TermsAndConditions";
import {Helmet} from "react-helmet-async";

const TermsAndConditionsPage = () => {
    const metaTitle = "Uplumi Marketing | Terms And Conditions"
    const metaDescriptionContent = "By using this site it is understood that you accept these terms and conditions. Continuing to visit this site implies that you agree."
    const metaOGDescriptionContent = "By using this site it is understood that you accept these terms and conditions. Continuing to visit this site implies that you agree."

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>

                <meta
                    name="description"
                    content={metaDescriptionContent}
                />

                <meta property="og:title" content={metaTitle}/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" itemProp="image" content="https://uplumi-marketing.com/opengraph/logo.jpg"/>
                <meta property="og:image:type" content="image/jpeg"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>
                <meta property="og:url" content="https://uplumi-marketing.com/terms-and-conditions"/>
                <meta property="og:description" content={metaOGDescriptionContent}/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:site_name" content="Uplumi Marketing"/>
                <meta property="twitter:card" content="summary_large_image"/>

                <link rel="canonical" href="https://uplumi-marketing.com/terms-and-conditions"/>
            </Helmet>

            <TermsAndConditions />
        </>
    )
}

export default TermsAndConditionsPage
