import React, {useCallback} from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import HomePage from "../../pages/Home";
import ContactPage from "../../pages/Contact";
import Navbar from "../Navbar";
import {ROUTES} from "../../constants";
import Footer from "../Footer";
import TeamPage from "../../pages/Team";
import ServicesPage from "../../pages/Services";
import type { Container, Engine } from "tsparticles-engine";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import TermsAndConditionsPage from "../../pages/TermsAndConditions";
import PrivacyPolicyPage from "../../pages/PrivacyPolicy";
import ChangeLanguage from "../ChangeLanguage";
import BlogsPage from "../../pages/Blogs";
import DigitalMarketingBlogPage from "../../pages/DigitalMarketingBlog";
import GoogleAdsBlogPage from "../../pages/GoogleAdsBlog";
import BestSiteForOnlineStoreBlogPage from "../../pages/BestSiteForOnlineStoreBlog";
import SuccessfulSocialMediaBlogPage from "../../pages/SuccessfulSocialMediaBlog";

const Router = () => {

    const particlesInit = useCallback(async (engine: Engine) => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {
        return;
    }, []);

    return (
        <Box display="grid">
            <BrowserRouter>
                    <ChangeLanguage />
                    <Navbar />
                    <Particles
                        style={{zIndex: 1}}
                        init={particlesInit}
                        loaded={particlesLoaded}
                        options={{
                            background: {
                                color: {
                                    value: "#eeeeee",
                                },
                            },
                            fpsLimit: 120,
                            interactivity: {
                                events: {
                                    onClick: {
                                        enable: true,
                                        mode: "push",
                                    },
                                    onHover: {
                                        enable: true,
                                        mode: "repulse",
                                    },
                                    resize: true,
                                },
                                modes: {
                                    push: {
                                        quantity: 1,
                                    },
                                    repulse: {
                                        distance: 200,
                                        duration: 0.1,
                                    },
                                },
                            },
                            particles: {
                                color: {
                                    value: "rgb(237,92,86)",
                                },
                                firefly: {
                                    color: "rgb(237,92,86)",
                                    distance: 300,
                                    enable: true,
                                    opacity: 0.5,
                                    width: 1,
                                },
                                collisions: {
                                    enable: true,
                                },
                                move: {
                                    direction: "none",
                                    enable: true,
                                    outModes: {
                                        default: "bounce",
                                    },
                                    random: false,
                                    speed: 1,
                                    straight: false,
                                },
                                number: {
                                    density: {
                                        enable: true,
                                        area: 1000,
                                    },
                                    value: 40,
                                },
                                opacity: {
                                    value: 0.5,
                                },
                                shape: {
                                    type: "circle",
                                },
                                size: {
                                    value: { min: 0.1, max: 3 },
                                },
                            },
                            detectRetina: true,
                        }}
                    />
                    <div style={{minHeight: '50vh', zIndex: 2}}>
                        <Routes>
                            <Route index path={ROUTES.home} element={<HomePage />} />
                            <Route path={ROUTES.offer} element={<ServicesPage />} />
                            <Route path={ROUTES.team} element={<TeamPage />} />
                            <Route path={ROUTES.contact} element={<ContactPage />} />
                            <Route path={ROUTES.terms_and_conditions} element={<TermsAndConditionsPage />} />
                            <Route path={ROUTES.privacy_policy} element={<PrivacyPolicyPage />} />
                            <Route path={ROUTES.blogs} element={<BlogsPage />} />
                            <Route path={ROUTES.secrets_of_digital_marketing_blog} element={<DigitalMarketingBlogPage />} />
                            <Route path={ROUTES.google_ads_blog} element={<GoogleAdsBlogPage />} />
                            <Route path={ROUTES.best_site_for_online_store_blog} element={<BestSiteForOnlineStoreBlogPage />} />
                            <Route path={ROUTES.successful_social_media_blog} element={<SuccessfulSocialMediaBlogPage />} />
                            <Route path="/*" element={<Navigate to={ROUTES.home} />} />
                        </Routes>
                    </div>

                    <div style={{background: '#17131b', zIndex: 2}}>
                        <Footer />
                    </div>
            </BrowserRouter>
        </Box>
    )
}

export default Router
