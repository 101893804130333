import React from 'react'
import {Box, Grid, Link, Typography} from "@mui/material";
import {appSecondaryColor, ROUTES} from "../../../constants";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
//works
const LegalSection = () => {
    const {t} = useTranslation(null, {keyPrefix: "footer"})
    const navigate = useNavigate()

    return (
        <Grid item xs={12} sm={6} md={6} lg={3} sx={{my: 4}}>
            <Box display="grid" gap={1}>
                <Typography variant="h2" fontSize="26px" fontWeight="bold" color={appSecondaryColor}>
                    Legal
                </Typography>

                <Typography color="white" fontSize="16px">
                    {t('identification_number')}: RO47849119
                </Typography>

                <Typography color="white" fontSize="16px">
                    {t('onrc')} : J35/375/2023
                </Typography>

                <Link onClick={() => navigate(ROUTES.terms_and_conditions)} sx={{'&:hover': {cursor: 'pointer', textDecoration: 'underline', textDecorationColor: 'white'}}}>
                    <Typography color="white" fontSize="16px">
                        {t('terms_and_conditions')}
                    </Typography>
                </Link>

                <Link onClick={() => navigate(ROUTES.privacy_policy)} sx={{'&:hover': {cursor: 'pointer', textDecoration: 'underline', textDecorationColor: 'white'}}}>
                    <Typography color="white" fontSize="16px">
                        {t('privacy_policy')}
                    </Typography>
                </Link>

                {/*<Link sx={{'&:hover': {cursor: 'pointer', textDecoration: 'underline', textDecorationColor: 'white'}}}>*/}
                {/*    <Typography color="white" fontSize="16px">*/}
                {/*        {t('cookie_policy')}*/}
                {/*    </Typography>*/}
                {/*</Link>*/}

                <Box display="flex" gap={1}>
                    <Link href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO">
                        <img
                            width="140"
                            height="40"
                            title="ANPC sol"
                            loading="eager"
                            alt="ANPC sol"
                            src={require('../../../resources/images/anpc1.png')}/>
                    </Link>

                    <Link href="https://anpc.ro/ce-este-sal/">
                        <img
                            width="140"
                            height="40"
                            title="ANPC sal"
                            loading="eager"
                            alt="ANPC sal"
                            src={require('../../../resources/images/anpc_sal.png')}/>
                    </Link>
                </Box>
            </Box>
        </Grid>
    )
}

export default LegalSection
