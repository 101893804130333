import React from 'react'
import {Box} from "@mui/material";
import ServicesCoverSection from "./ServicesCoverSection";
import SEO from "./SEO";
import WebsiteBuilding from "./WebsiteBuilding";
import FreeMeeting from "../FreeMeeting";
import PaidAdvertising from "./PaidAdvertising";
import EmailMarketing from "./EmailMarketing";
import TakeTheNextStep from "../TakeTheNextStep";
import SocialMediaManagement from "./SocialMediaManagement";
import FreeConsultation from "./FreeConsultation";
import WhyUplumi from "../WhyUplumi";
import ContactForm from "../ContactForm";
import LogoDesign from "./LogoDesign";
import VisualIdentity from "./VisualIdentity";

const Services = () => {

    return (
        <Box display="grid">
            <ServicesCoverSection />

            <WebsiteBuilding />

            <SEO />

            <FreeMeeting />

            <PaidAdvertising />

            <EmailMarketing />

            <TakeTheNextStep />

            <SocialMediaManagement />

            <FreeConsultation />

            <FreeMeeting />

            <VisualIdentity />

            <LogoDesign />

            <WhyUplumi />

            <ContactForm />
        </Box>
    )
}

export default Services
