import React from 'react'
import { Box, Typography } from "@mui/material";
import { appSecondaryColor, orangeColor } from "../../../../constants";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTranslation} from "react-i18next";

const AuditBenefits = () => {
    const {t} = useTranslation(null, {keyPrefix: "contact_page"})
    const matches = useMediaQuery('(min-width:450px)');

    return (
        <>
            <Typography
                sx={{ color: appSecondaryColor, fontWeight: 'bold' }}
                fontSize={ matches ? '24px' : '18px' }
                variant="h2"
            >
                {t('suitable_for')}
            </Typography>

            <Box display="flex" gap={1}>
                <CheckBoxIcon sx={{ color: orangeColor, marginTop: matches ? '5px' : 0 }}/>

                <Typography
                    sx={{ width: matches ? '75%' : '100%', marginBlockStart: 0, marginBlockEnd: 0 }}
                    paragraph
                    fontSize={ matches ? '20px' : '16px' }
                >
                    {t('reason_first')}{' '}
                    <span style={{ display: 'inline', fontWeight: 'bold' }}>
                        {t('reason_first_bold')}
                    </span>
                </Typography>
            </Box>

            <Box display="flex" gap={1}>
                <CheckBoxIcon sx={{ color: orangeColor, marginTop: matches ? '5px' : 0  }}/>

                <Typography
                    sx={{ width: matches ? '75%' : '100%', marginBlockStart: 0, marginBlockEnd: 0 }}
                    paragraph
                    fontSize={ matches ? '20px' : '16px' }
                >
                    {t('reason_second')}{' '}
                    <span style={{ display: 'inline', fontWeight: 'bold' }}>online</span>
                </Typography>
            </Box>

            <Box display="flex" gap={1}>
                <CheckBoxIcon sx={{ color: orangeColor, marginTop: matches ? '5px' : 0  }}/>

                <Typography
                    sx={{ width: matches ? '75%' : '100%', marginBlockStart: 0, marginBlockEnd: 0 }}
                    paragraph
                    fontSize={ matches ? '20px' : '16px' }
                >
                    {t('reason_third')}{' '}
                    <span style={{ display: 'inline', fontWeight: 'bold' }}>
                        {t('reason_third_bold')}
                    </span>
                </Typography>
            </Box>

            <Box display="flex" gap={1}>
                <CheckBoxIcon sx={{ color: orangeColor, marginTop: matches ? '5px' : 0 }}/>

                <Typography
                    sx={{ width: matches ? '75%' : '100%', marginBlockStart: 0, marginBlockEnd: 0 }}
                    paragraph
                    fontSize={ matches ? '20px' : '16px' }
                >
                    {t('reason_forth')}
                    <span style={{ display: 'inline', fontWeight: 'bold' }}>
                        {' '}{t('reason_forth_bold')}{' '}
                    </span>
                </Typography>
            </Box>

            <Box display="flex" gap={1}>
                <CheckBoxIcon sx={{ color: orangeColor, marginTop: matches ? '5px' : 0 }}/>

                <Typography
                    sx={{ width: matches ? '75%' : '100%', marginBlockStart: 0, marginBlockEnd: 0 }}
                    paragraph
                    fontSize={ matches ? '20px' : '16px' }
                >
                    {t('reason_fifth')}
                    <span style={{ display: 'inline', fontWeight: 'bold' }}>
                        {' '}{t('reason_fifth_bold')}{' '}
                    </span>
                </Typography>
            </Box>

            <Box display="flex" gap={1}>
                <CheckBoxIcon sx={{ color: orangeColor, marginTop: matches ? '5px' : 0 }}/>

                <Typography
                    sx={{ width: matches ? '75%' : '100%', marginBlockStart: 0, marginBlockEnd: 0 }}
                    paragraph
                    fontSize={ matches ? '20px' : '16px' }
                >
                    {t('reason_sixth')}
                    <span style={{ display: 'inline', fontWeight: 'bold' }}>
                        {' '}{t('reason_sixth_bold')}{' '}
                    </span>
                </Typography>
            </Box>

            <Box display="flex" gap={1}>
                <CheckBoxIcon sx={{ color: orangeColor, marginTop: matches ? '5px' : 0  }}/>

                <Typography
                    sx={{ width: matches ? '75%' : '100%', marginBlockStart: 0, marginBlockEnd: 0 }}
                    paragraph
                    fontSize={ matches ? '20px' : '16px' }
                >
                    {t('reason_seventh')}{' '}
                    <span style={{ display: 'inline', fontWeight: 'bold' }}>
                        {t('reason_seventh_bold')}
                    </span>
                </Typography>
            </Box>
        </>
    )
}

export default AuditBenefits
