import React from 'react'
import {Box} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import TeamCoverSection from "./TeamCoverSection";
import TeamPresentationSection from "./TeamPresentationSection";
import HomeAboutUs from "../HomeAboutUs";
import TakeTheNextStep from "../TakeTheNextStep";
import FreeMeeting from "../FreeMeeting";
import ContactForm from "../ContactForm";
import AuditCalendly from "../Contact/AuditCalendly";

const Team = () => {
    const matches = useMediaQuery('(min-width:850px)')

    return (
        <Box display="grid">
            <TeamCoverSection />

            <TeamPresentationSection />

            <Box margin={matches ? 0 : 'auto'} gap={matches ? 0 : 1} display={matches ? 'flex' : 'grid'} sx={{ maxWidth: '100vw', pb: matches ? 0 : 2 }}>
                <img
                    height={matches ? '400' : '300'}
                    width={matches ? '400' : '300'}
                    title="Laptop showing shopify as a website builder engine"
                    loading="eager"
                    alt="Laptop showing shopify as a website builder engine"
                    src={require('../../resources/images/free-audit.png')}
                    style={{display: "block", margin: 'auto'}}
                />

                <Box width={matches ? '50%' : '100%'}>
                    <AuditCalendly />
                </Box>
            </Box>

            <TakeTheNextStep />

            <HomeAboutUs />

            <FreeMeeting />

            <ContactForm />
        </Box>
    )
}

export default Team
