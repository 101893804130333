import React from 'react'
import FreeMeeting from "../../components/FreeMeeting";
import ContactForm from "../../components/ContactForm";
import {Box} from "@mui/material";
import {Helmet} from "react-helmet-async";
import GoogleAdsBlogCover from "../../components/GoogleAdsBlog/GoogleAdsBlogCover";
import GoogleAdsBlog from "../../components/GoogleAdsBlog";

const GoogleAdsBlogPage = () => {
    const metaTitle = "Uplumi Marketing | Blogs | From Zero to Hero: How Google Ads Can Transform Your Business";
    const metaDescriptionContent = `Discover the Power of Google Ads: A Comprehensive Guide to Business Transformation in ${new Date().getFullYear()}`;
    const metaOGDescriptionContent = `Discover the Power of Google Ads: A Comprehensive Guide to Business Transformation in ${new Date().getFullYear()}`;


    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>

                <meta
                    name="description"
                    content={metaDescriptionContent}
                />

                <meta property="og:title" content={metaTitle}/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://uplumi-marketing.com/opengraph/logo.jpg"/>
                <meta property="og:image:type" content="image/jpeg"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>
                <meta property="og:url" content="https://uplumi-marketing.com/blogs/google-ads"/>
                <meta property="og:description" content={metaOGDescriptionContent}/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:site_name" content="Uplumi Marketing"/>
                <meta property="twitter:card" content="summary_large_image"/>

                <link rel="canonical" href="https://uplumi-marketing.com/blogs/google-ads"/>
            </Helmet>

            <Box width="100%" sx={{background: 'white'}}>
                <GoogleAdsBlogCover />

                <GoogleAdsBlog />

                <FreeMeeting />

                <ContactForm />
            </Box>
        </>
    )
}

export default GoogleAdsBlogPage
