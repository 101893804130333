import React from 'react'
import {Box} from "@mui/material";
import CoverSection from "./CoverSection";
import TermsAndConditionsSection from "./TermsAndConditionsSection";

const TermsAndConditions = () => {
    return (
        <Box display="grid" gap={4} sx={{background: 'white'}}>
            <CoverSection />

            <TermsAndConditionsSection />
        </Box>
    )
}

export default TermsAndConditions
