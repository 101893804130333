import React from 'react'
import {Box, Divider} from "@mui/material";
import TakeTheNextStep from "../TakeTheNextStep";
import ContactForm from "../ContactForm";
import ContactCoverSection from "./ContactCoverSection";
import FreeMeeting from "../FreeMeeting";
import AuditBenefits from "./AuditBenefits";
import AuditIntro from "./AuditIntro";
import CalendlySection from "./CalendlySection";

const Contact = () => {
    return (
        <Box display="grid">
            <ContactCoverSection />

            <AuditIntro />

            <FreeMeeting />

            <CalendlySection />

            <Divider />

            <AuditBenefits />

            <TakeTheNextStep />

            <ContactForm />
        </Box>
    )
}

export default Contact
