import React from 'react'
import { Helmet } from "react-helmet-async";
import Team from "../../components/Team";

const TeamPage = () => {


    const metaTitle = "Uplumi Marketing | Meet Our Marketing Team Of Experts";
    const metaDescriptionContent = "With astounding digital marketing experience, our team of experts are here to help you scale and grow your business. Schedule a free audit call now.";

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>

                <meta
                    name="description"
                    content={metaDescriptionContent}
                />

                <meta property="og:title" content={metaTitle}/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://uplumi-marketing.com/opengraph/logo.jpg"/>
                <meta property="og:image:type" content="image/jpeg"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>
                <meta property="og:url" content="https://uplumi-marketing.com/agency-team"/>
                <meta property="og:description" content={metaDescriptionContent}/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:site_name" content="Uplumi Marketing"/>
                <meta property="twitter:card" content="summary_large_image"/>

                <link rel="canonical" href="https://uplumi-marketing.com/agency-team"/>
            </Helmet>

            <Team />
        </>
    )
}

export default TeamPage
