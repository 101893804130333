import React from 'react'
import {appBackgroundColor, appPrimaryColor, appSecondaryColor, ROUTES} from "../../constants";
import { Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion'
import {useTranslation} from "react-i18next";

const AuditButton = ({text, bgColor = appSecondaryColor}:{text?: string, bgColor?: string}) => {
    const navigate = useNavigate()
    const matches = useMediaQuery('(min-width:450px)')
    const {t} = useTranslation(null, {keyPrefix: "audit_button"})

    return (
        <motion.button
            whileHover={{ scale: 1.1, backgroundColor: appPrimaryColor }}
            onClick={() => navigate(ROUTES.contact)}
            style={{
                borderRadius: 5,
                backgroundColor: bgColor,
                color: appBackgroundColor,
                maxWidth: 300,
                minHeight: 50,
                display: "grid",
                textTransform: 'none',
                margin: matches ? 0 : 'auto',
                padding: "10px",
                border: 0,
                cursor: 'pointer',
            }}
        >
            <Typography fontWeight="bold" paragraph fontSize="18px" sx={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
                {text ?? t('speak_to_our_team_today')}
            </Typography>
            <Typography paragraph fontSize="12px" sx={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
                {t('plan_free_audit')}
            </Typography>
        </motion.button>
    )
}
export default AuditButton
