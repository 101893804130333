import React from 'react'
import {Box, Grid, Typography} from "@mui/material";
import UplumiSection from "./UplumiSection";
import CompanySection from "./CompanySection";
import ServicesSection from "./ServicesSection";
import LegalSection from "./LegalSection";
import NewsletterSubscribe from "../Newsletter/NewsletterSubscribe";
import {footerBackgroundColor} from "../../constants";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t} = useTranslation(null, {keyPrefix: "footer"})

    return (
        <Box display="grid" gap={2}>
            <Grid container
                  display="flex"
                  flexDirection="row"
                  padding={5}
            >
                <UplumiSection />

                <CompanySection />

                <ServicesSection />

                <LegalSection />
            </Grid>

            <Box display="flex" justifyContent="center">
                <NewsletterSubscribe />
            </Box>

            <Box sx={{backgroundColor: footerBackgroundColor}} display="flex" justifyContent="center" padding={4}>
                <Typography color="#bdbdbd">
                    Copyright © {new Date().getFullYear()} Uplumi Marketing. {t('rights')}
                </Typography>
            </Box>
        </Box>
    )
}

export default Footer
