import React from 'react'
import {Box, Typography, useMediaQuery} from "@mui/material";
import AuditButton from "../Button";
import {useTranslation} from "react-i18next";

const HomeAboutUs = () => {
    const {t} = useTranslation(null, {keyPrefix: "home_about_us"})
    const matches = useMediaQuery('(min-width:1000px)')

    return (
        <Box display={matches ? 'flex' : 'grid'} justifyContent="center" padding={5} sx={{maxWidth: '100vw'}} gap={5}>
            <img
                height="auto"
                width="auto"
                title="digital marketing team at work"
                loading="eager"
                alt="digital marketing team"
                src={require('../../resources/images/home7.png')}
                style={{display: "block", width: matches ? '45%' : '100%', maxWidth: matches ? '45%' : '100%', margin: 'auto'}}
            />

            <Box display="grid" width={matches ? '45%' : '90%'} gap={2} margin="auto">
                <Typography variant="h2" fontSize='16px' color="#f9a825">
                    {t('marketing_agency')}
                </Typography>

                <Typography variant="h2" fontSize='28px' fontWeight="bold">
                    {t('about_us_title')}
                </Typography>

                <Typography>
                    {t('first_paragraph')}
                </Typography>

                <Typography>
                    {t('second_paragraph')}
                </Typography>

                <Typography>
                    {t('third_paragraph')}
                </Typography>

                <AuditButton />
            </Box>
        </Box>
    )
}

export default HomeAboutUs
