import React from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { appPrimaryColor, ROUTES } from "../../constants";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

const SandwichMenu = () => {
    const {t} = useTranslation(null, {keyPrefix: "navbar"})
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button onClick={handleClick}>
                <MenuIcon />
            </Button>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    sx={{ fontWeight: 'bold', margin: 1, color: appPrimaryColor }}
                    onClick={() => {
                        handleClose()
                        navigate(ROUTES.home)
                    }}
                >
                    {t('home')}
                </MenuItem>

                <MenuItem
                    sx={{ fontWeight: 'bold', margin: 1, color: appPrimaryColor }}
                    onClick={() => {
                        handleClose()
                        navigate(ROUTES.offer)
                    }}
                >
                    {t('services')}
                </MenuItem>

                <MenuItem
                    sx={{ fontWeight: 'bold', margin: 1, color: appPrimaryColor }}
                    onClick={() => {
                        handleClose()
                        navigate(ROUTES.team)
                    }}
                >
                    {t('meet_the_team')}
                </MenuItem>

                <MenuItem
                    sx={{ fontWeight: 'bold', margin: 1, color: appPrimaryColor }}
                    onClick={() => {
                        handleClose()
                        navigate(ROUTES.contact)
                    }}
                >
                    {t('contact')}
                </MenuItem>

                <MenuItem
                    sx={{ fontWeight: 'bold', margin: 1, color: appPrimaryColor }}
                    onClick={() => {
                        handleClose()
                        navigate(ROUTES.blogs)
                    }}
                >
                    {t('blogs')}
                </MenuItem>
            </Menu>
        </>
    );
}

export default SandwichMenu
