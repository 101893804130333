import React from 'react'
import {Fab, Grid, Link, useMediaQuery} from "@mui/material";
import HomeTitle from "../../components/HomeTitle";
import HomeImage from "../../components/HomeImage";
import { Helmet } from "react-helmet-async";
import {appPrimaryColor} from "../../constants";
import CallIcon from "@mui/icons-material/Call";
import HomeAboutUs from "../../components/HomeAboutUs";
import WhyUplumi from "../../components/WhyUplumi";
import ContactForm from "../../components/ContactForm";
import TakeTheNextStep from "../../components/TakeTheNextStep";

const HomePage = () => {
    const matches = useMediaQuery('(min-width:600px)')
    const metaTitle = "Uplumi Marketing | Social Media Marketing Agency"
    const metaDescriptionContent = "See how you can blow up your E-commerce or Info Product business sales via Paid Advertising and SEO. Let our experts handle your digital marketing."
    const metaOGDescriptionContent = "See how you can blow up your business sales via Paid Advertising, SEO and Email Marketing. Let our experts handle your digital marketing."

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>

                <meta
                    name="description"
                    content={metaDescriptionContent}
                />

                <meta property="og:title" content={metaTitle}/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" itemProp="image" content="https://uplumi-marketing.com/opengraph/logo.jpg"/>
                <meta property="og:image:type" content="image/jpeg"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>
                <meta property="og:url" content="https://uplumi-marketing.com/"/>
                <meta property="og:description" content={metaOGDescriptionContent}/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:site_name" content="Uplumi Marketing"/>
                <meta property="twitter:card" content="summary_large_image"/>

                <link rel="canonical" href="https://uplumi-marketing.com/"/>
            </Helmet>

            <Link sx={{display: 'flex', justifyContent: 'end', flexDirection: 'row'}} href="tel:+40727337520">
                <Fab variant="extended" size="small" sx={{padding: 2, backgroundColor: appPrimaryColor,'&:hover':{background: appPrimaryColor}, color: 'white', margin: 'auto'}} >
                    <CallIcon sx={{ mr: 1 }}/>
                    +40 727 337 520
                </Fab>
            </Link>

            <Grid container display={matches ? 'flex' : 'grid'} flexDirection="row" justifyContent="center" sx={{ maxWidth: '100vw', px: 2.5, py: 2.5 }}>
                <HomeTitle />

                <Grid item xs={12} sm={8} md={8} lg={5} margin={4}>
                    <img
                        width="100%" height="auto"
                        title="digital marketing team work illustration"
                        loading="eager"
                        alt="digital marketing team at work"
                        src={require('../../resources/images/marketing team at work.png')}/>
                </Grid>
            </Grid>

            <HomeImage />

            <HomeAboutUs />

            <WhyUplumi />

            <ContactForm />

            <TakeTheNextStep />
        </>
    )
}

export default HomePage
