import React from 'react'
import {Box, useMediaQuery} from "@mui/material";
import AuditPresentation from "../AuditPresentation";


const AuditIntro = () => {
    const matches = useMediaQuery('(min-width:850px)')

    return (
        <Box display={matches ? 'flex' : 'grid'} gap={matches ? 0 : 2} padding={5} sx={{ maxWidth: '100vw', background: 'white' }}>
            <AuditPresentation />

            {
                matches &&
                    <img
                        height="100%"
                        width="50%"
                        title="Laptop showing shopify as a website builder engine"
                        loading="eager"
                        alt="Laptop showing shopify as a website builder engine"
                        src={require('../../../resources/images/meeting.png')}
                        style={{display: "block", margin: 'auto'}}
                    />
            }
        </Box>
    )
}

export default AuditIntro
