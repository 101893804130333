import React from 'react'
import { Helmet } from "react-helmet-async";
import Services from "../../components/Services";

const ServicesPage = () => {
    const metaTitle = "Uplumi Marketing | Digital Marketing Services";
    const metaDescriptionContent = "Uplumi Marketing agency provides paid advertising, SEO and social media management services in order to blow up your sales and achieve astounding ROI.";
    const metaOGDescriptionContent = "Uplumi Marketing agency provides paid advertising, SEO, email marketing, website building and social media management services in order to blow up your sales and achieve astounding ROI.";

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>

                <meta
                    name="description"
                    content={metaDescriptionContent}
                />

                <meta property="og:title" content={metaTitle}/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://uplumi-marketing.com/opengraph/services.jpg"/>
                <meta property="og:image:type" content="image/jpeg"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>
                <meta property="og:url" content="https://uplumi-marketing.com/marketing-services"/>
                <meta property="og:description" content={metaOGDescriptionContent}/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:site_name" content="Uplumi Marketing"/>
                <meta property="twitter:card" content="summary_large_image"/>

                <link rel="canonical" href="https://uplumi-marketing.com/marketing-services"/>
            </Helmet>

            <Services />
        </>
    )
}

export default ServicesPage
