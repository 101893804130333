import React from 'react'
import {Box, Typography} from "@mui/material";
// @ts-ignore
import background from '../../resources/images/nextstep.png'
import AuditButton from "../Button";
import {useTranslation} from "react-i18next";

const TakeTheNextStep = () => {
    const {t} = useTranslation(null, {keyPrefix: "take_next_step"})

    return (
        <Box sx={{ backgroundImage: `url(${background})`}}>
            <Box display="grid" sx={{ background: 'rgba(31, 105, 142, 0.96)'}} gap={2} padding={10} zIndex={1}>
                <Typography variant="h2" fontWeight="bold" fontSize="24px" color="white" margin="auto" zIndex={10}>
                    {t('first_prop')}
                </Typography>

                <Typography variant="h2" fontSize="18px" color="white" margin="auto">
                    {t('second_prop')}
                </Typography>

                <Typography variant="h2" fontSize="18px" color="white" margin="auto">
                    {t('schedule_a')} <strong>{t('free')}</strong> {t('45_min_consult')}
                </Typography>

                <Box margin="auto">
                    <AuditButton />
                </Box>
            </Box>
        </Box>
    )
}

export default TakeTheNextStep
