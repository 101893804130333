import React from 'react'
import MailchimpSubscribe from "react-mailchimp-subscribe"
import NewsletterForm from "../NewsletterForm";

const NewsletterSubscribe = () => {
    return (
        <MailchimpSubscribe
            url={String(process.env.REACT_APP_PUBLIC_MAILCHIMP_URL)}
            render={ ( props ) => {
                const { subscribe, status, message } = props || {};
                return (
                    <NewsletterForm
                        status={ status }
                        message={ message }
                        onValidated={ formData => subscribe( formData ) }
                    />
                );
            } }
        />
    );
}

export default NewsletterSubscribe
