import React from 'react'
import {Box, Fab, Link, Typography, useMediaQuery} from "@mui/material";
import {appPrimaryColor} from "../../constants";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Form from "./Form/Form";
import {useTranslation} from "react-i18next";

const ContactForm = () => {
    const {t} = useTranslation(null, {keyPrefix: "contact_form"})
    const matches = useMediaQuery('(min-width:850px)')

    return (
        <Box display={matches ? 'flex' : 'grid'} justifyContent="center" padding={5} sx={{maxWidth: '100vw', backgroundColor: '#eeeeee'}} gap={5}>
            <div style={matches ? {width: "50%"} : {width: '100%'}}>
                <iframe title="google-maps-frame" width="100%" height={matches ? '675' : '200'} frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0}
                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Timisoara,%20Romania+(Uplumi%20Marketing)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                    <a href="https://www.maps.ie/distance-area-calculator.html">measure acres/hectares on map</a></iframe>
            </div>

            <Box display="grid" alignContent="start" gap={1.5}>
                <Box display="flex" gap={2}>
                    <img
                        title="Company logo"
                        loading="eager"
                        alt="Our agency logo containing company initials"
                        src={require('../../resources/images/marketing agency logo.png')}
                        width={matches ? '80px' : '70px'}
                        height={matches ? '50px' : '40px'}
                    />

                    <Typography fontWeight="bold" variant="h2" fontSize={matches ? "32px" : "24px"} margin="auto">
                        {t('contact_form')}
                    </Typography>
                </Box>

                <Typography>
                    {t('contact_form_subtitle')}
                </Typography>

               <Box display={matches ? "flex" : "grid"} justifyContent={matches ? "start": "center"} gap={2}>
                   <Link href="tel:+40727337520">
                       <Fab variant="extended" size="small" sx={{padding: 2, backgroundColor: appPrimaryColor,'&:hover':{background: appPrimaryColor}, color: 'white'}} >
                           <CallIcon sx={{ mr: 1 }}/>
                           +40 727 337 520
                       </Fab>
                   </Link>

                   <Link href="https://wa.me/+40727337520" target="_blank" rel="noopener">
                       <Fab variant="extended" size="small" sx={{padding: 2, backgroundColor: appPrimaryColor,'&:hover':{background: appPrimaryColor}, color: 'white'}} >
                           <WhatsAppIcon sx={{ mr: 1, fill: '#66bb6a' }}/>
                           +40 727 337 520
                       </Fab>
                   </Link>
               </Box>

                <Typography>
                    {t('email_at')} <strong>uplumi.marketing@gmail.com</strong>
                </Typography>

                <Typography>
                    {t('or_fill_form')}
                </Typography>

                <Form/>
            </Box>
        </Box>

    )
}

export default ContactForm
