import React from 'react'
import {Box, useMediaQuery} from "@mui/material";
import {footerBackgroundColor} from "../../../constants";
import GeoAvatar from "./GeoAvatar";
import ValiAvatar from "./ValiAvatar";

const TeamPresentationSection = () => {
    const matches = useMediaQuery('(min-width:850px)')

    return (
        <Box display="grid" sx={{background: footerBackgroundColor, pt: 4, pb: 4}}>
            <Box display={matches ? 'flex' : 'grid'} justifyContent={matches ? 'space-evenly' : 'center'} gap={6}>
                <GeoAvatar />

                <ValiAvatar />
            </Box>
        </Box>
    )
}

export default TeamPresentationSection
