import React from 'react'
import {Box, Typography, useMediaQuery} from "@mui/material";
import {appPrimaryColor, orangeColor} from "../../../constants";
import AuditButton from "../../Button";
import {useTranslation} from "react-i18next";

const FreeConsultation = () => {
    const {t} = useTranslation(null, {keyPrefix: "services"})
    const matches = useMediaQuery('(min-width:850px)')

    return (
        <Box display={matches ? 'flex' : 'grid'} sx={{padding: 5, background: 'white'}} gap={4}>
            <Box display="grid" sx={{boxShadow: 3, background: 'white', width: matches ? '50%' : '100%', padding: matches ? 5 : 0, gap: matches ? 2 : 1, borderRadius: 2}}>
                <Typography variant="h2" fontSize="20px" color={appPrimaryColor} fontWeight={600} sx={{textDecoration: 'underline', pl: matches ? 0 : 2, pr: matches ? 0 : 2, pt: matches ? 0 : 2}}>
                    6. {t('free_consultation')}
                </Typography>

                <Typography paragraph fontWeight="500" sx={{textIndent: '50px', pl: matches ? 0 : 2, pr: matches ? 0 : 2}}>
                    {t('free_consultation_first')}
                </Typography>

                <Typography paragraph fontWeight="500" sx={{textIndent: '50px', pl: matches ? 0 : 2, pr: matches ? 0 : 2}}>
                    {t('free_consultation_second')}
                </Typography>

                <Typography paragraph fontWeight="500" sx={{textIndent: '50px', pl: matches ? 0 : 2, pr: matches ? 0 : 2}}>
                    {t('free_consultation_third')}
                </Typography>

                <Box display="flex" justifyContent="center" sx={{mb: matches ? 0 : 1}}>
                    <AuditButton bgColor={orangeColor}/>
                </Box>
            </Box>

            {
                matches ?
                    <img
                        height="450"
                        width="50%"
                        title="two marketing guys working"
                        loading="eager"
                        alt="two marketing guys working"
                        src={require('../../../resources/images/marketing-consultation.png')}
                        style={{margin: 'auto'}}
                    />
                    :
                    <img
                        height="350"
                        width="100%"
                        title="two marketing guys working"
                        loading="eager"
                        alt="two marketing guys working"
                        src={require('../../../resources/images/marketing-consultation-mobile.png')}
                        style={{margin: 'auto'}}
                    />
            }
        </Box>
    )
}

export default FreeConsultation
