import React from 'react'
import {useTranslation} from "react-i18next";
import {Box, Typography, useMediaQuery} from "@mui/material";
// @ts-ignore
import background from "../../../resources/images/nextstep.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const BestSiteForOnlineStoreCover = () => {
    const {t} = useTranslation()
    const matches = useMediaQuery('(min-width:450px)')

    return (
        <Box sx={{ backgroundImage: `url(${background})`}}>
            <Box display="grid" sx={{ background: 'rgba(31, 105, 142, 0.96)'}} gap={2} padding={10} zIndex={1}>
                <Box display="flex" justifyContent="center">
                    <Typography variant="h1" fontWeight="bold" fontSize={matches ? '32px' : '24px'} color="white" margin="auto">
                        {t('blogs.best_site_for_online_store.cover_msg')}
                    </Typography>
                </Box>

                <Box display="flex" justifyContent="center">
                    <Typography fontWeight="bold" paragraph variant="body1" color="white">
                        Uplumi Marketing <FiberManualRecordIcon sx={{ fill: '#ef6c00', width: 10, height: 10, transition: 'opacity .2s, visibility .2s'}}/> {t('blogs.blogs')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default BestSiteForOnlineStoreCover
