import React from 'react'
import {Box, Typography, useMediaQuery} from "@mui/material";
import {appPrimaryColor} from "../../../constants";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {useTranslation} from "react-i18next";

const CoverSection = () => {
    const {t} = useTranslation(null, {keyPrefix: "terms_and_conditions"})
    const matches = useMediaQuery('(min-width:650px)')

    return (
        <Box display={matches ? 'flex' : 'grid'} alignContent="center" width="100%">
            <img
                height="400"
                width={matches ? '50%' : '100%'}
                title="two marketing guys working"
                loading="eager"
                alt="two marketing guys working"
                src={require('../../../resources/images/termsandcond.png')}
            />

            <Box display="grid" alignContent="center" width={matches ? '50%' : '100%'} gap={1}>
                <Box display="flex" justifyContent="center">
                    <Typography variant="h1" fontWeight="bold" fontSize="32px" color={appPrimaryColor} margin="auto">
                        {t('terms_and_conditions')}
                    </Typography>
                </Box>

                <Box display="flex" justifyContent="center">
                    <Typography fontWeight="bold" paragraph variant="body1">
                        {t('home')} <FiberManualRecordIcon sx={{ fill: '#ef6c00', width: 10, height: 10, transition: 'opacity .2s, visibility .2s'}}/> {t('terms_and_conditions')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default CoverSection
