import React from 'react'
import {Box, useMediaQuery} from "@mui/material";
import AuditAvatars from "./AuditAvatars";
import AuditDescription from "./AuditDescription";
import AuditBenefits from "./AuditBenefits";

const AuditPresentation = () => {
    const matches = useMediaQuery('(min-width:850px)')

    return (
        <Box display="grid" justifyContent="center" width={matches ? '50%' : '100%'} gap={2}>
            <AuditAvatars />

            <AuditDescription />

            <AuditBenefits />
        </Box>
    )
}

export default AuditPresentation
