import React from "react";
import {useTranslation} from "react-i18next";
import {Box, useMediaQuery} from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../constants";


const BlogCards = () => {
    const {t} = useTranslation(null, {keyPrefix: "blogs"})
    const matches = useMediaQuery('(min-width:650px)')
    const navigate = useNavigate();

    return (
        <Box display="grid" gap={4} sx={{ maxWidth: '100vw', px: 2.5, py: 2.5 }}>
            <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={2}>
                <Card sx={{ width: matches ? '30%' : '100%', transition: 'all .4s ease-in-out', '&:hover':{cursor: 'pointer', transform: 'scale(1.05)' } }}>
                    <CardMedia
                        component="img"
                        width="200"
                        height="235"
                        alt="Social media"
                        title="Social media"
                        src={require('../../resources/images/social-media.jpeg')}
                        loading="eager"
                    />
                    <CardContent sx={{height: 100, overflow: 'auto'}}>
                        <Box display="grid">
                            <Typography sx={{alignSelf: 'center'}} variant="body1" fontWeight="bold">{t('successful_social_media_blog.title')}</Typography>

                            <Typography sx={{alignSelf: 'center'}} variant="body1">{t('successful_social_media_blog.publish_date')}</Typography>
                        </Box>

                        <Typography variant="body2" color="text.secondary" sx={{mb: 0.5, mt: 0.5}}>
                            {t('successful_social_media_blog.blog_card_short_description')}
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button variant="contained" size="small" onClick={() => navigate(ROUTES.successful_social_media_blog)}>
                            {t('read_more')}
                        </Button>
                    </CardActions>
                </Card>

                <Card sx={{ width: matches ? '30%' : '100%', transition: 'all .4s ease-in-out', '&:hover':{cursor: 'pointer', transform: 'scale(1.05)' } }}>
                    <CardMedia
                        component="img"
                        width="200"
                        height="235"
                        alt="Laptop placed on a desk which is showing html code"
                        title="Laptop screen showing how we build websites"
                        src={require('../../resources/images/digital-marketing-blog-cover.jpeg')}
                        loading="eager"
                    />
                    <CardContent sx={{height: 100, overflow: 'auto'}}>
                        <Box display="grid">
                            <Typography sx={{alignSelf: 'center'}} variant="body1" fontWeight="bold">{t('digital_marketing_blog.title')}</Typography>

                            <Typography sx={{alignSelf: 'center'}} variant="body1">{t('digital_marketing_blog.publish_date')}</Typography>
                        </Box>

                        <Typography variant="body2" color="text.secondary" sx={{mb: 0.5, mt: 0.5}}>
                            {t('digital_marketing_blog.blog_card_short_description')}
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button variant="contained" size="small" onClick={() => navigate(ROUTES.secrets_of_digital_marketing_blog)}>
                            {t('read_more')}
                        </Button>
                    </CardActions>
                </Card>

                <Card sx={{ width: matches ? '30%' : '100%', transition: 'all .4s ease-in-out', '&:hover':{cursor: 'pointer', transform: 'scale(1.05)' } }}>
                    <CardMedia
                        component="img"
                        width="200"
                        height="235"
                        alt="Man working on laptop and showing digital marketing services"
                        title="Man working on laptop and showing digital marketing services"
                        src={require('../../resources/images/google_ads_blog_cover.jpeg')}
                        loading="lazy"
                    />
                    <CardContent sx={{height: 120, overflow: 'auto'}}>
                        <Box display="grid">
                            <Typography sx={{alignSelf: 'center'}} variant="body1" fontWeight="bold">{t('google_ads_blog.title', {date: new Date().getFullYear()})}</Typography>

                            <Typography sx={{alignSelf: 'center'}} variant="body1">{t('google_ads_blog.publish_date')}</Typography>
                        </Box>

                        <Typography variant="body2" color="text.secondary" sx={{mb: 0.5, mt: 0.5}}>
                            {t('google_ads_blog.blog_card_short_description')}
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button variant="contained" size="small" onClick={() => navigate(ROUTES.google_ads_blog)}>
                            {t('read_more')}
                        </Button>
                    </CardActions>
                </Card>

                <Card sx={{ width: matches ? '30%' : '100%', transition: 'all .4s ease-in-out', '&:hover':{cursor: 'pointer', transform: 'scale(1.05)' } }}>
                    <CardMedia
                        component="img"
                        width="200"
                        height="235"
                        alt="Man working on laptop and showing digital marketing services"
                        title="Man working on laptop and showing digital marketing services"
                        src={require('../../resources/images/best_online_store_cover.jpeg')}
                        loading="lazy"
                    />
                    <CardContent sx={{height: 120, overflow: 'auto'}}>
                        <Box display="grid">
                            <Typography sx={{alignSelf: 'center'}} variant="body1" fontWeight="bold">{t('best_site_for_online_store.title')}</Typography>

                            <Typography sx={{alignSelf: 'center'}} variant="body1">{t('best_site_for_online_store.publish_date')}</Typography>
                        </Box>

                        <Typography variant="body2" color="text.secondary" sx={{mb: 0.5, mt: 0.5}}>
                            {t('best_site_for_online_store.blog_card_short_description')}
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button variant="contained" size="small" onClick={() => navigate(ROUTES.best_site_for_online_store_blog)}>
                            {t('read_more')}
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </Box>
    )
}

export default BlogCards
