import React from 'react'
import {Box, Grid, IconButton, Typography} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import {appSecondaryColor, facebookColor} from "../../../constants";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {useTranslation} from "react-i18next";

const UplumiSection = () => {
    const {t} = useTranslation(null, {keyPrefix: "footer"})

    return (
        <Grid item xs={12} sm={6} md={6} lg={4} sx={{my: 4}}>
            <Box display="grid" gap={1}>
                <Typography variant="h2" fontSize="26px" fontWeight="bold" color={appSecondaryColor}>
                    UPLUMI MARKETING
                </Typography>

                <Typography color="white" fontSize="16px">
                    {t('uplumi_description')}
                </Typography>

                <Box display="flex">
                    <IconButton href='https://www.facebook.com/profile.php?id=100087973156286'>
                        <FacebookIcon sx={{ color: facebookColor, height: 35, width: 35 }}/>
                    </IconButton>

                    <IconButton href='https://www.instagram.com/uplumi.marketing/'>
                        <InstagramIcon sx={{ color: '#f44336', height: 35, width: 35 }}/>
                    </IconButton>

                    <IconButton href='https://www.linkedin.com/company/uplumi-marketing'>
                        <LinkedInIcon sx={{color: '#0e76a8', height: 35, width: 35 }} />
                    </IconButton>
                </Box>
            </Box>
        </Grid>
    )
}

export default UplumiSection
