import React from 'react'
import {Box, useMediaQuery} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Card from "@mui/material/Card";
import {appPrimaryColor, appSecondaryColor} from "../../constants";
import {useTranslation} from "react-i18next";

const HomeImage = () => {
    const {t} = useTranslation(null, {keyPrefix: "home_image"})
    const matches = useMediaQuery('(min-width:650px)')

    return (
            <Box display="grid" gap={4} sx={{ maxWidth: '100vw', px: 2.5, py: 2.5, background: '#1B4059' }}>
                <Typography variant="h2" fontSize="28px" fontWeight="bold" margin="auto" color="white" sx={{my: 4}}>
                    {t('what_we_do_best')}
                </Typography>

                <Box display="flex" justifyContent="space-evenly" flexWrap="wrap" gap={2}>
                    <Card sx={{ width: matches ? '30%' : '100%', transition: 'all .4s ease-in-out', '&:hover':{cursor: 'pointer', transform: 'scale(1.1)' } }}>
                        <CardMedia
                            component="img"
                            width="200"
                            height="200"
                            alt="Laptop placed on a desk which is showing html code"
                            title="Laptop screen showing how we build websites"
                            src={require('../../resources/images/home1.png')}
                            loading="eager"
                        />
                        <CardContent sx={{height: 120, overflow: 'auto'}}>
                            <Typography gutterBottom variant="h2" fontSize="24px" fontWeight="500" color={appPrimaryColor}>
                                {t('website_building')}
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                                {t('website_building_description')}
                            </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                            <IconButton aria-label="add to favorites">
                                <FavoriteIcon sx={{fill: appSecondaryColor}}/>
                            </IconButton>
                        </CardActions>
                    </Card>

                    <Card sx={{ width: matches ? '30%' : '100%', transition: 'all .4s ease-in-out', '&:hover':{cursor: 'pointer', transform: 'scale(1.1)' } }}>
                        <CardMedia
                            component="img"
                            width="200"
                            height="200"
                            loading="eager"
                            alt="Phone showing the diagram of accounts reached with facebook ads"
                            title="Phone screen showing meta campaigns charts"
                            src={require('../../resources/images/home2.png')}
                        />
                        <CardContent sx={{height: 120, overflow: 'auto'}}>
                            <Typography gutterBottom variant="h2" fontSize="24px" fontWeight="500" color={appPrimaryColor}>
                                {t('paid_advertising')}
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                                {t('paid_advertising_description')}
                            </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                            <IconButton aria-label="add to favorites">
                                <FavoriteIcon sx={{fill: appSecondaryColor}}/>
                            </IconButton>
                        </CardActions>
                    </Card>

                    <Card sx={{ width: matches ? '30%' : '100%', transition: 'all .4s ease-in-out', '&:hover':{cursor: 'pointer', transform: 'scale(1.1)' } }}>
                        <CardMedia
                            component="img"
                            width="200"
                            height="200"
                            loading="eager"
                            alt="Phone screen showing the social media apps we can manage as a service"
                            title="Phone screen showing the social media apps"
                            src={require('../../resources/images/home3.png')}
                        />
                        <CardContent sx={{height: 120, overflow: 'auto'}}>
                            <Typography gutterBottom variant="h2" fontSize="24px" fontWeight="500" component="div">
                                Social Management
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                                {t('social_management_description')}
                            </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                            <IconButton aria-label="add to favorites">
                                <FavoriteIcon sx={{fill: appSecondaryColor}}/>
                            </IconButton>
                        </CardActions>
                    </Card>
                </Box>

                <Box display="flex" justifyContent="space-evenly" flexWrap="wrap" gap={2}>
                    <Card sx={{ width: matches ? '30%' : '100%', transition: 'all .4s ease-in-out', '&:hover':{cursor: 'pointer', transform: 'scale(1.1)' } }}>
                        <CardMedia
                            component="img"
                            width="200"
                            height="200"
                            loading="eager"
                            alt="Gmail user interface"
                            title="Gmail user interface"
                            src={require('../../resources/images/home4.png')}
                        />
                        <CardContent sx={{height: 120, overflow: 'auto'}}>
                            <Typography gutterBottom variant="h2" fontSize="24px" fontWeight="500" color={appPrimaryColor}>
                                {t('email_marketing')}
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                                {t('email_marketing_description')}
                            </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                            <IconButton aria-label="add to favorites">
                                <FavoriteIcon sx={{fill: appSecondaryColor}}/>
                            </IconButton>
                        </CardActions>
                    </Card>

                    <Card sx={{ width: matches ? '30%' : '100%', transition: 'all .4s ease-in-out', '&:hover':{cursor: 'pointer', transform: 'scale(1.1)' } }}>
                        <CardMedia
                            width="200"
                            height="200"
                            component="img"
                            loading="eager"
                            alt="Image showing google search console performance"
                            title="Image showing google search console performance"
                            src={require('../../resources/images/home5.png')}
                        />
                        <CardContent sx={{height: 120, overflow: 'auto'}}>
                            <Typography gutterBottom variant="h2" fontSize="24px" fontWeight="500" color={appPrimaryColor}>
                                SEO
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                                {t('seo_description')}
                            </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                            <IconButton aria-label="add to favorites">
                                <FavoriteIcon sx={{fill: appSecondaryColor}}/>
                            </IconButton>
                        </CardActions>
                    </Card>

                    <Card sx={{ width: matches ? '30%' : '100%', transition: 'all .4s ease-in-out', '&:hover':{cursor: 'pointer', transform: 'scale(1.1)' } }}>
                        <CardMedia
                            component="img"
                            loading="eager"
                            alt="Photo suggesting the concept of creating a marketing strategy"
                            title="Photo suggesting the concept of creating a marketing strategy"
                            src={require('../../resources/images/home6.png')}
                            width="200"
                            height="200"
                        />
                        <CardContent sx={{height: 120, overflow: 'auto'}}>
                            <Typography gutterBottom variant="h2" fontSize="24px" fontWeight="500" color={appPrimaryColor}>
                                {t('free_consultation')}
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                                {t('free_consultation_description')}
                            </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                            <IconButton aria-label="add to favorites">
                                <FavoriteIcon sx={{fill: appSecondaryColor}}/>
                            </IconButton>
                        </CardActions>
                    </Card>
                </Box>
            </Box>
    )
}

export default HomeImage
