export const navBarBackgroundColor = '#fafafa'

export const appPrimaryColor = '#1E3C52'

export const appSecondaryColor = '#e91e63'

export const calendlyUsername = 'uplumi-marketing'

export const appBackgroundColor = '#eeeeee'

export const facebookColor = '#4267B2'

export const checkIconColor = '#4caf50'

export const orangeColor = '#ef6c00'

export const footerBackgroundColor = '#212121'

export const ROUTES = {
    home: '/',
    offer: '/marketing-services',
    team: '/agency-team',
    contact: '/contact',
    terms_and_conditions: '/terms-and-conditions',
    privacy_policy: '/privacy-policy',
    blogs: '/blogs',
    secrets_of_digital_marketing_blog: '/blogs/secrets-of-digital-marketing',
    google_ads_blog: '/blogs/google-ads',
    best_site_for_online_store_blog: '/blogs/best-site-for-online-store',
    successful_social_media_blog: '/blogs/successful-social-media-campaign'
}
