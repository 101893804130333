import React from 'react'
import { Box, Grid } from "@mui/material";
import { InlineWidget, PopupButton } from "react-calendly";
import { appSecondaryColor, calendlyUsername, navBarBackgroundColor } from "../../../constants";
import useMediaQuery from "@mui/material/useMediaQuery";

const AuditCalendly = () => {
    const matches = useMediaQuery('(min-width:450px)');

    return (
        <Grid container display="flex" flexDirection="column" justifyContent="center" sx={{ mt: matches ? 10 : 0 }}>
            {
                matches ?
                    <InlineWidget url={`https://calendly.com/${calendlyUsername}`}/>
                    :
                    <Box display="flex" justifyContent="center" sx={{ mt: 5 }}>
                        <PopupButton
                            url={`https://calendly.com/${calendlyUsername}`}
                            rootElement={document.getElementById("root") as HTMLElement}
                            text="Schedule Free Audit Now!"
                            styles={{
                                backgroundColor: appSecondaryColor,
                                border: `1px solid ${appSecondaryColor}`,
                                borderRadius: 20,
                                color: navBarBackgroundColor,
                                width: '85%',
                                padding: 10,
                                fontWeight: 'bold',
                                fontSize: '18px'
                            }}
                        />
                    </Box>
            }
        </Grid>
    )
}

export default AuditCalendly
