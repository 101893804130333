import React, {useState} from 'react'
import {EmailFormFields} from "react-mailchimp-subscribe";
import {Box, CircularProgress, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {appSecondaryColor} from "../../../constants";
import {useTranslation} from "react-i18next";

type TNewsletterFormProps = {
    status: "error" | "sending" | "success" | null,
    message: string | Error | null
    onValidated: (data: EmailFormFields) => void
}

const NewsletterForm = ({ status, message, onValidated }: TNewsletterFormProps) => {
    const {t} = useTranslation(null, {keyPrefix: "footer"})
    const [ , setError ] = useState<string | null>(null);
    const [ email, setEmail ] = useState<string | null>('');

    const handleFormSubmit = () => {
        if (!email) {
            setError( 'Please enter a valid email address' );
            return null;
        }

        const isFormValidated = onValidated({ EMAIL: email });

        setError(null);

        return email && email.indexOf("@") > -1 && isFormValidated;
    }

    const handleInputKeyEvent = ( event: React.KeyboardEvent<HTMLDivElement> ) => {
        setError(null);
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
            event.preventDefault();
            handleFormSubmit();
        }
    }

    const getMessage = (message: string | Error | null) => {
        if ( !message ) {
            return null;
        }

        if (typeof message === "string") {
            const result = message?.split('-') ?? null;

            if ( "0" !== result?.[0]?.trim() ) {
                return message
            }
            const formattedMessage = result?.[1]?.trim() ?? null;
            return formattedMessage ? formattedMessage : null;
        }
    }

    return (
        <Box display="grid" gap={1}>
            <Typography variant="h2" fontSize="20px" fontWeight="600" color="white" margin="auto">
                {t('subscribe')}
            </Typography>

            <Box display="flex">
                <TextField
                    onChange={(event) => setEmail(event?.target?.value)}
                    onKeyUp={(event: React.KeyboardEvent<HTMLDivElement>) => handleInputKeyEvent(event)}
                    type="email"
                    placeholder="email"
                    value={email}
                    size="small"
                    fullWidth
                    InputProps={{ sx: {
                            border: `1px solid white`,
                            borderRadius: '4px',
                        }
                    }}
                    sx={{
                        borderColor: 'white',
                        '& .MuiInputBase-input':
                            {
                                color: 'white', borderColor: 'white'
                            },
                        '& .MuiOutlinedInput-notchedOutline':
                            {
                                borderColor: 'white',
                                borderBottomRightRadius: 0,
                                borderTopRightRadius: 0,
                                '&:hover':{
                                    borderColor: 'red'
                                }
                            },
                }}
                />

                <Button
                    variant="contained"
                    size="small"
                    sx={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0, '&:hover':{backgroundColor: appSecondaryColor}, backgroundColor: appSecondaryColor}}
                    onClick={handleFormSubmit}
                >
                    {t('submit')}
                </Button>

                { status ==='sending' && <CircularProgress sx={{color: 'white', ml: 2}}/> }
            </Box>

            { status === 'error' && message &&
                (
                    <Typography paragraph fontSize="20px" color="#f44336" margin="auto">
                        {getMessage(message)}
                    </Typography>
                )
            }

            { status === 'success' &&
                (
                    <Typography paragraph fontSize="20px" color="white" margin="auto">
                        {getMessage(message)}
                    </Typography>
                )
            }
        </Box>
    )
}

export default NewsletterForm
